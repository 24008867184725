.newsletter {
    flex: 1 1 33.3333%;

    @include breakpoint(tablet) {
        flex-basis: 100%;
    }

    .button {
        transition: margin .3s ease;
    }
}

.popup--newsletter.popup--newsletter {
    padding: 0px;
    border-radius: 4px;
    overflow: hidden;
    max-width: 994px;

    & button.mfp-close, button.mfp-close:active {
        top: 20px;
        right: 20px;
    }
}

.popup--newsletter.popup--newsletter.popup--newsletter-submitted {
    max-width: 450px;
    padding: 40px 30px;
}

    .newsletter__controls {
        display: flex;
        flex-wrap: wrap;
        padding: 40px 40px 40px 0;

        @include breakpoint(tablet) {
            padding-left: 40px;
            display: block;
        }

        .popup--newsletter & {
            flex-direction: column;
            flex-wrap: nowrap;
            height: 100%;
        }
    }

        .popup--newsletter .form__fieldset {
            margin-bottom: 25px;
        }

        .newsletter__title {
            font-family: $f-title-stack;
            @include font(38px, 48px);

            @include breakpoint(tablet) {
                @include font(18px, 24px);
            }

            flex: 0 0 100%;
            margin-bottom: 10px;

            .popup--newsletter & {
                flex-basis: auto;
                @include font(38px, 48px);
            }
        }

        .newsletter__label {
            @include font(16px, 24px);

            display: block;
            flex: 0 0 100%;
            margin-bottom: 30px;

            .popup--newsletter & {
                flex-basis: auto;
            }
        }

        input.newsletter__input {
            @include body;

            flex: 1 1 50%;
            min-width: 0;
            height: 40px;
            padding-right: 0;
            padding-left: 0;
            border-bottom: 1px solid $c-newsletter-border;
            transition: none;

            @include breakpoint(phablet) {
                margin-bottom: 20px;
            }

            &:focus {
                @include focus;

                margin-top: -1px;
                margin-right: 4px;
                margin-left: -4px;
                padding-left: 4px;
                border-bottom: none;
            }

            @include placeholderStyles {
                color: rgba($c-black, 0.5);
            }

            .popup--newsletter & {
                margin-bottom: 15px;
            }
        }

        input.newsletter__input-popup {
            @include body;

            margin-bottom: 25px;
            border: 1px solid $c-border;
            border-radius: 3px;
        }

        .newsletter__terms {
            @include body;
            @include font(12px, 22px);
            margin-top: 18px;

            @include breakpoint(tablet) {
                margin-top: 73px;
            }

            a {
                margin: -4px;
                padding: 4px;
                color: $c-black;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;

                }
            }
        }

        .newsletter__label {
            @include font(16px, 24px);
            a {
                margin: -4px;
                padding: 4px;
                color: $c-black;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;

                }
            }
        }

        .newsletter__image-wrap {
            @include breakpoint(tablet) {
                display: none;
            }
        }
