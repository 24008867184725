.wishlist-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr 2fr;
        gap: 15px;
    }
}

    .wishlist-item__image {

        img {
            width: 100%;
            height: auto;
        }

        figcaption a {
            @include boldText;

            text-align: center;
            margin-top: 12px;
            display: block;

            @include breakpoint(tablet) {
                @include font(12px, 14px);
            }
        }
    }

    .wishlist-item__content {
        padding-top: 20px;

        @include breakpoint(tablet) {
            padding: 0;
        }

        .wishlist-item--print & {
            padding: 0;
            break-inside: avoid;
        }
    }

        .wishlist-item__title {
            @include font(30px, 38px);

            font-family: $f-title-stack;
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                @include font(18px, 24px);

                margin-bottom: 5px;
            }

            .wishlist-item--print & {
                @include apply-map($f-primary);
                @include font(16px, 24px);
            }
        }

            .wishlist-item__link {
                text-decoration: none;
                color: $c-title;
            }

        .wishlist-item__prices {
            @include font(14px, 20px);

            margin-bottom: 10px;
            display: grid;
            grid-template-columns: repeat(2, min-content);
            column-gap: 10px;
        }

        .wishlist-item__prices--offer {
            grid-template-columns: repeat(3, min-content);
        }

            .wishlist-item__price {
                width: min-content;
            }

            .wishlist-item__price--offer {
                text-decoration: line-through;
            }

            .wishlist-item__price-label {
                width: min-content;
            }

        .wishlist-item__stock {
            margin-bottom: 10px;
        }

        .wishlist-item--discontinued {
            color: $c-grey-text;
        }
