.shipping-control {
    @include font(14px, 18px);
    @include mediumText;

    @include breakpoint(desktop) {
        @include font(13px, 18px);
    }

    @include breakpoint(nav) {
        @include font(14px, 18px);
    }

    a {
        margin: -13px;
        padding: 4px;
        color: inherit;
        text-decoration: none;
        white-space: nowrap;

        .footer & {
            @include boldText();
            margin-left: 10px;
        }

        @include breakpoint(desktop) {
            margin: -4px;
        }
    }
}
