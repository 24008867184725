@mixin wrap {
    margin: 0 auto;
    width: $site-width;
    max-width: $site-max-width;
    padding: 0 $site-gutter;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter--mobile;
    }
}

.wrap {
   @include wrap;
}

.wrap--flush {
    padding-left: 0;
    padding-right: 0;
}

.wrap--m-flush {
    @include breakpoint(tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}

.wrap--narrow {
    max-width: 760px;
}

.wrap--wide {
    max-width: 1172px;
}

.wrap--full {
    max-width: none;
}

.wrap--relative {
    position: relative;
}
