@import 'tools/mixins/_grid';
@import 'tools/mixins/_grid-rows';
@import 'tools/mixins/_grid-columns';
@import 'tools/mixins/_grid-row';

$mobileNavWidth: 496px;

.header-nav {}

    .header-nav__outer {
        @include allowForBars((
            desktop: false,
            nav: 0,
            tablet: 0,
            phablet: 0
        ), top);

        padding: 8px 0;

        @include breakpoint(nav) {
            display: none;
        }

        [header-activating="true"] & {
            @include allowForBars((
                default: -$headerHeight,
                nav: -$headerHeightMobile,
                tablet: -$headerHeightMobile,
                phablet: -$headerHeightMobile
            ));
        }

        [show-notice] & {
            @include allowForBars((
                desktop: false,
                nav: $noticeHeight,
                tablet: $noticeHeight,
                phablet: $noticeHeight
            ), top, (
                desktop: false,
                nav: true
            ));
        }

        [show-two-notice] & {
            @include allowForBars((
                desktop: false,
                nav: $noticeHeight * 2,
                tablet: $noticeHeight * 2,
                phablet: $noticeHeight * 2
            ), top, (
                desktop: false,
                nav: true
            ));
        }

        [header-active="true"] & {
            @include breakpoint(nav) {
                display: block;
                position: fixed;
                right: 0;
                bottom: 0;
                width: 100%;
                max-width: $mobileNavWidth;
                padding: 0;
                background: $c-white;
            }
        }

        .header-nav__outer-bg {
            position: absolute;
            top: 0;
            right: 100%;
            width: calc(100vw - $mobileNavWidth);
            height: 100%;
            background: rgba($c-black, .7);
        }
    }

        .header-nav__middle {

            @include breakpoint(nav) {
                height: 100%;
                overflow: auto;
            }
        }

            .header-nav__inner {
                position: relative;
                display: flex;
                align-items: center;

                @include breakpoint(nav) {
                    display: block;
                    padding: 60px 24px 30px;
                }
            }

                .header-nav__close {
                    display: none;

                    @include breakpoint(nav) {
                        display: block;
                        position: absolute;
                        top: 24px;
                        right: 24px;
                        width: 24px;
                        height: 24px;
                        border-radius: 12px;
                        padding: 0;
                        background: $c-black;
                    }

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        display: block;
                        width: 9px;
                        height: 9px;
                        transform: translate(-50%, -50%);
                        fill: $c-white;
                        stroke: $c-white;
                    }

                    &:focus:not(:hover) {
                        @include focus(100%, ($c-white, $c-black), 2px);
                    }
                }

                .header-nav__list {
                    flex-shrink: 0;
                }

                .header-nav__list--top {

                    @include breakpoint(nav) {
                        margin-bottom: 20px;
                    }

                    [header-item-active="true"] & {

                        @include breakpoint(nav) {
                            margin-bottom: 0;
                        }
                    }
                }

                .header-nav__list {
                    > .grid--loose {
                        @include breakpoint(nav) {
                            @include grid-gutter();

                            > * {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .header-nav__links {
                    width: 33.3333%;
                    min-height: 100%;
                    padding: 0 calc($site-gutter / 2);

                    @include breakpoint(nav) {
                        width: 100%;
                        padding: 0;
                    }
                }

                .header-nav__links--quad {
                    width: 66.6666%;

                    @include breakpoint(nav) {
                        width: 100%;
                    }
                }

                .header-nav__links--full {
                    width: 100%;
                }

                .header-nav__cta {
                    @include font(13px, 24px);
                    @include boldText;

                    display: none;
                    color: inherit;
                    text-decoration: none;

                    .header-nav__item--sub-active & {
                        display: none;
                    }

                    @include breakpoint(nav) {
                        display: block;
                        margin: 0 #{-$site-gutter--mobile};
                        padding: 20px $site-gutter--mobile;
                    }
                }

                    .header-nav__item {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;

                        @include breakpoint(nav) {
                            display: flex;
                        }

                        &:last-child {

                            @include breakpoint(nav) {
                                border-bottom: none;
                            }
                        }

                        .header-nav__list--top > &:not(.header-nav__item--active):not(.header-nav__item--sub-active) {

                            [header-item-active="true"] & {

                                @include breakpoint(nav) {
                                    display: none;
                                }
                            }
                        }
                    }

                    .header-nav__item--block {
                        @include margin(bottom, 14px);

                        display: block;

                        @include breakpoint(nav) {
                            margin-bottom: 0;
                        }
                    }

                    .header-nav__item--category {

                        @include breakpoint(nav) {
                            display: block;
                        }
                    }

                    .header-nav__item--subcategory {
                        @include margin(bottom, 40px);

                        @include breakpoint(nav) {
                            display: block;
                            margin-bottom: 0;
                        }
                    }

                    .header-nav__item--active,
                    .header-nav__item--sub-active {

                        @include breakpoint(nav) {
                            display: block;
                        }
                    }

                        .header-nav__link {
                            @include font(14px, 24px);

                            position: relative;
                            display: inline-flex;
                            justify-content: space-between;
                            align-items: center;
                            margin: 8px 0;
                            padding: 0 6px;
                            color: inherit;
                            text-decoration: none;

                            @include breakpoint(desktop) {
                                @include font(13px, 24px);
                            }

                            @include breakpoint(nav) {
                                @include font(18px, 23px);

                                display: block;
                                width: calc(100% + #{$site-gutter--mobile * 2});
                                margin: 0 #{-$site-gutter--mobile};
                                padding: 10px $site-gutter--mobile;
                            }

                            &:hover {
                                text-decoration: none;

                                &:after {
                                    width: calc(100% - 16px);

                                    @include breakpoint(nav) {
                                        display: none;
                                    }
                                }
                            }

                            &:focus:not(:hover) {

                                @include breakpoint(nav) {
                                    @include focus;

                                    z-index: 1;
                                }
                            }

                            .header-nav__content & {
                                @include font(16px, 24px); // Important: Line height must remain divisible by 4 (otherwise the nav grid will break) Joe

                                margin: 0 -8px;

                                @include breakpoint(nav) {
                                    margin: 0 #{-$site-gutter--mobile};
                                }
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: -2px;
                                left: 8px;
                                width: 0;
                                height: 1px;
                                background: rgba($c-black, 0.2);
                                transition: width .3s ease;
                            }
                        }

                            .header-nav__name {
                                @include font(24px, 46px);
                                @include margin(bottom, 5px);

                                @include breakpoint(nav) {
                                    @include font(18px, 24px);

                                    margin: 0;
                                }
                            }

                            .header-nav__text {
                                @include breakpoint(nav) {
                                    display: none;
                                }
                            }

                        .header-nav__button {
                            &:before {
                                @include hideSafely;
                            }

                            svg {
                                display: block;
                            }

                            .header-nav__item--subcategory & {
                                display: none;

                                @include breakpoint(nav) {
                                    display: block;
                                }
                            }

                            .header-nav__item--sub-active & {
                                @include breakpoint(nav) {
                                    display: none;
                                }
                            }

                            .header-nav__item--sub-active .header-nav__item--active & {
                                @include breakpoint(nav) {
                                    display: block;
                                }
                            }
                        }

                        .header-nav__button--category,
                        .header-nav__button--subcategory {
                            position: static;
                            top: auto;
                            left: auto;
                            clip: auto;
                            clip-path: none;
                            height: auto;
                            width: auto;
                            padding: 0 6px;
                            margin: 8px 0;
                            background: transparent;
                            line-height: 1;

                            @include breakpoint(desktop) {
                                padding: 0 4px;
                            }

                            @include breakpoint(nav) {
                                top: 50%;
                                right: 0;
                                left: auto;
                                width: calc(100% + #{$site-gutter--mobile * 2});
                                margin: 0 #{-$site-gutter--mobile};
                                padding: 10px $site-gutter--mobile;
                                text-align: left;
                            }

                            &:focus:not(:hover) {

                                @include breakpoint(nav) {
                                    z-index: 1;
                                }
                            }

                            .header-nav__item--active > & {

                                @include breakpoint(nav) {
                                    position: absolute;
                                    top: 0;
                                    right: auto;
                                    left: 0;
                                    width: auto;
                                    transform: translateY(-100%);
                                }
                            }

                            svg {
                                display: none;
                                fill: $c-black;
                                width: 5px;
                                height: 8px;

                                @include breakpoint(nav) {
                                    display: block;
                                }

                                .header-nav__item--active > & {

                                    @include breakpoint(nav) {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .header-nav__item--active > & svg {

                                @include breakpoint(nav) {
                                    margin-left: 0;
                                }
                            }
                        }

                        .header-nav__button--menu {

                            @include breakpoint(nav) {
                                top: 50%;
                                right: $site-gutter - 5px;
                                left: auto;
                                width: 30px;
                                height: 30px;
                                padding: 0;
                                background: transparent;
                                transform: translateY(-50%);
                                position: absolute;
                            }

                            @include breakpoint(tablet) {
                                right: 10px;
                            }

                            // .header-nav--active & {

                            //     @include breakpoint(nav) {
                            //         top: - $headerHeightMobile / 2;
                            //     }
                            // }

                            svg {
                                transition: fill .3s ease;

                                .header--no-transition & {
                                    transition: none;
                                }

                                .header--white & {
                                    fill: $c-white;
                                }

                                .header--scroll & {
                                    fill: $c-black;
                                }

                                @include breakpoint(nav) {
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                        }

                            .header-nav__button-content {

                                @include breakpoint(nav) {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                            }

                                .header-nav__button-label {
                                    @include font(14px, 24px);
                                    @include mediumText;

                                    @include breakpoint(desktop) {
                                        @include font(13px, 24px);
                                    }

                                    @include breakpoint(nav) {
                                        @include font(18px, 24px);
                                    }

                                    position: relative;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        bottom: -1px;
                                        left: 0;
                                        width: 0;
                                        height: 1px;
                                        background: rgba($c-black, 0.2);
                                        transition: width .3s ease;
                                    }

                                    .header-nav__item--active > .header-nav__button & {

                                        @include breakpoint(nav) {
                                            display: none;
                                        }

                                        &:after {
                                            width: 100%;
                                        }
                                    }

                                    .header-nav__button:hover & {

                                        &:after {
                                            width: 100%;

                                            @include breakpoint(nav) {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                        .header-nav__label {
                            @include font(22px, 36px); // Important: Line height must remain divisible by 4 (otherwise the nav grid will break) Joe

                            margin: 0 0 28px;

                            @include breakpoint(nav) {
                                @include font(18px, 20px); // Important: Line height must remain divisible by 4 (otherwise the nav grid will break) Joe

                                margin: 0;
                                padding: 20px $site-gutter--mobile;
                                text-transform: none;
                                color: inherit;
                            }

                            .header-nav__item--has-button > & {
                                display: none;
                            }

                            .header-nav__item--active & {

                                @include breakpoint(nav) {
                                    @include font(14px, 42px);

                                    padding: 0;
                                }
                            }

                            .header-nav__item--active > & {

                                @include breakpoint(nav) {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    max-width: calc(100% - 84px);
                                    transform: translate(-50%, -100%);
                                    text-align: center;
                                }
                            }
                        }

                        .header-nav__subcategory-label {
                            @include font(22px, 36px); // Important: Line height must remain divisible by 4 (otherwise the nav grid will break) Joe

                            margin: 0 -2px 15px;

                            @include breakpoint(nav) {
                                display: none;
                                @include font(18px, 20px); // Important: Line height must remain divisible by 4 (otherwise the nav grid will break) Joe
                            }

                            .header-nav__item--subcategory.header-nav__item--active > & {

                                @include breakpoint(nav) {
                                    @include font(14px, 42px);

                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    max-width: calc(100% - 84px);
                                    transform: translate(-50%, -100%);
                                    text-align: center;
                                }
                            }
                        }

                        .header-nav__content {
                            display: none;
                            font-size: 0;

                            .header-nav__item--active &,
                            .header-nav__item--sub-active & {
                                display: block;
                            }

                            &:not(.header-nav__content--last) {

                                .header-nav__item--active > & {
                                    @include allowForBars((
                                        default: $headerHeight,
                                        nav: false
                                    ), $notice: (
                                        default: true,
                                        nav: false
                                    ));

                                    position: fixed;
                                    right: 0;
                                    left: 0;
                                    padding: 24px 0 20px 0;
                                    background: $c-background;
                                    z-index: 2;

                                    @include breakpoint(nav) {
                                        position: static;
                                        padding: 0;
                                        background: transparent;
                                    }

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        top: 100%;
                                        right: 0;
                                        left: 0;
                                        height: 20px;
                                        background: linear-gradient(180deg, $c-black 0%, rgba($c-black, 0) 100%);
                                        opacity: 0.15;

                                        @include breakpoint(nav) {
                                            display: none;
                                        }
                                    }
                                }

                                [show-notice] .header-nav__item--active > & {
                                    @include allowForBars((
                                        default: $headerHeight + $noticeHeight,
                                        nav: false
                                    ), $notice: (
                                        default: true,
                                        nav: false
                                    ));
                                }

                                [show-two-notice] .header-nav__item--active > & {
                                    @include allowForBars((
                                        default: $headerHeight + $noticeHeight * 2,
                                        nav: false
                                    ), $notice: (
                                        default: true,
                                        nav: false
                                    ));
                                }

                            }
                        }

                        .header-nav__content--last {

                            .header-nav__item--active > & {

                                @include breakpoint(nav) {
                                    display: block;
                                    right: 0;
                                    left: 0;
                                    z-index: 1;
                                }
                            }
                        }

                        .header-nav__content {
                            .header-nav__item--subcategory & {
                                @include breakpoint(nav) {
                                    display: none;
                                }
                            }

                            .header-nav__item--subcategory.header-nav__item--active & {

                                @include breakpoint(nav) {
                                    display: block;
                                }
                            }
                        }

                            .header-nav__wrap {
                                @extend .wrap;

                                display: flex;
                                flex-wrap: wrap;
                                padding-right: calc($site-gutter / 2);
                                padding-left: calc($site-gutter / 2);

                                @include breakpoint(nav) {
                                    padding-right: 0;
                                    padding-left: 0;

                                    .l-one-half,
                                    .l-one-quarter {
                                        margin-bottom: 5px;
                                    }
                                }
                            }

                .header-nav__callouts {
                    width: 66.6666%;
                    padding: 0 calc($site-gutter / 2);

                    .header-nav__item--sub-active & {
                        display: none;
                    }

                    @include breakpoint(nav) {
                        display: none;
                    }
                }

                .header-nav__callouts--single {
                    width: 33.3333%;
                }

                .header-nav__callouts--n-true {
                    @include breakpoint(nav) {
                        display: block;
                        padding: 0;
                        width: 100%;
                        margin-top: 12px;

                        > div > div:last-child:not(:first-child) {
                            display: none;
                        }
                    }

                    .nav-callout__link {
                        @include breakpoint(nav) {
                            margin: 0;
                            padding: 0;
                        }
                    }

                    .nav-callout__image {
                        @include breakpoint(nav) {
                            display: block;
                        }
                    }
                }
