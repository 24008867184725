@import 'tools/mixins/_placeholder-styles';

.search {
    color: $c-black;
    transition: color .3s ease;

    .header--no-transition & {
        transition: none;
    }

    .header--white & {
        color: $c-white;
    }

    .header--scroll & {
        color: $c-black;
    }
}

.search--active {

    &:after {
        @include allowForBars((
            default: $headerHeight,
            tablet: $headerHeightMobile,
            phablet: $headerHeightMobile
        ), top);

        content: '';
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($c-search-background, 0.8);

        [show-notice] & {
            @include allowForBars((
                default: $headerHeight + $noticeHeight,
                tablet: $headerHeightMobile + $noticeHeight,
                phablet: $headerHeightMobile + $noticeHeight
            ), top, true);
        }

        [show-two-notice] & {
            @include allowForBars((
                default: $headerHeight + $noticeHeight * 2,
                tablet: $headerHeightMobile + $noticeHeight * 2,
                phablet: $headerHeightMobile + $noticeHeight * 2
            ), top, true);
        }
    }
}

    .search__button {
        position: relative;
        width: 34px;
        height: 40px;
        padding: 8px;
        background: transparent;

        &:focus:not(:hover) {
            @include focus;
        }
    }

        .search__text {
            @include hideSafely;
        }

        .search__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            svg {
                display: block;
                fill: currentColor;
            }
        }

    .search__controls {
        @include allowForBars((
            default: $headerHeight,
            tablet: $headerHeightMobile,
            phablet: $headerHeightMobile
        ), top);

        display: none;

        [show-notice] & {
            @include allowForBars((
                default: $headerHeight + $noticeHeight,
                tablet: $headerHeightMobile + $noticeHeight,
                phablet: $headerHeightMobile + $noticeHeight
            ), top, true);
        }

        [show-two-notice] & {
            @include allowForBars((
                default: $headerHeight + $noticeHeight * 2,
                tablet: $headerHeightMobile + $noticeHeight * 2,
                phablet: $headerHeightMobile + $noticeHeight * 2
            ), top, true);
        }

        .search--active & {
            display: block;
            position: fixed;
            right: 0;
            left: 0;
            padding: 0 $site-gutter;
            background: $c-white;
            z-index: 1;

            @media (max-height: $search-max-height) {
                overflow: auto;
            }
        }
    }

        .search__fieldset {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 712px;
            margin: 34px auto 60px;

            @include breakpoint(phablet) {
                margin-top: $site-gutter--mobile;
                margin-bottom: $site-gutter;
            }
        }

            .search__label {
                @include hideSafely;
            }

            input.search__input {
                @include font(30px, 50px);

                min-width: 0;
                height: 60px;
                margin-right: -24px;
                padding: 0 24px 0 0;
                color: inherit;
                border-bottom-color: $c-grey;

                @include breakpoint(phablet) {
                    @include font(18px, 21px);

                    height: 38px;
                }

                &:focus {
                    width: calc(100% + 37px);
                    margin-right: -29px;
                    margin-left: -8px;
                    padding-right: 29px;
                    padding-left: 8px;

                    @include breakpoint(phablet) {
                        width: calc(100% + 36px);
                        margin-right: -28px;
                        padding-right: 28px;
                    }
                }

                @include placeholderStyles {
                    color: $c-button--disabled;
                }

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    -webkit-text-fill-color: $c-body;
                    box-shadow: 0 0 0px 1000px $c-grey inset;
                    transition: background-color 5000s ease-in-out 0s;
                }

                &:-webkit-autofill:focus {
                    padding: 0 57px 0 19px;
                    border: 1px solid $c-black;
                }
            }

            .search__submit {
                position: relative;
                width: 30px;
                height: 60px;
                padding: 0;
                background: transparent;

                @include breakpoint(phablet) {
                    height: 38px;
                }

                &:focus {
                    width: 28px;
                }

                span {
                    @include hideSafely;
                }

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    fill: curentColor;
                    transform: translate(-50%, -50%);
                }
            }

        .search__results {
            display: none;
            margin-right: -$site-gutter;
            padding-right: $site-gutter;
            overflow: hidden;
            overflow-y: auto;

            @media (max-height: $search-max-height) {
                overflow-y: visible;
            }

            .search--has-results & {
                display: block;
            }
        }

            .search__products {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                margin-bottom: 35px;

                @include breakpoint(phablet) {
                    margin-bottom: 10px;
                }
            }

                .search__title {
                    @include body;

                    margin-right: auto;
                    margin-bottom: 20px;
                }

                .search__cta {
                    @include body;
                    @include boldText;

                    margin: 0 -4px;
                    padding: 0 4px;
                    color: inherit;
                    text-decoration: none;
                }

        .search__links {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 35px;

            @include breakpoint(phablet) {
                margin-bottom: 20px;
            }
        }

            .search__link {
                @include h5;
                @include margin(bottom, 10px);

                margin-right: -4px;
                margin-left: -4px;
                padding: 0 4px;
                color: inherit;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
