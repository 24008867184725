.pagination {
    text-align: center;
    position: relative;
}

    .pagination__wrap {
        display: flex;
        justify-content: center;
    }

        .pagination__links {
            display: flex;
        }

            .pagination__link {
                @include font(14px, 35px);

                height: 35px;
                min-width: 35px;
                text-decoration: none;
                color: inherit;

                @include breakpoint(tiny) {
                    @include font(14px, 30px);

                    height: 30px;
                    min-width: 30px;
                }
            }

            .pagination__link--active {
                @include boldText;   
            }

        .pagination__prev,
        .pagination__next {
            @include body;

            display: inline-flex;
            align-items: center;
            margin: 0 -8px;
            padding: 0 8px;
            color: inherit;
            text-decoration: none;

            &[disabled] {
                opacity: 0.5;
            }

            svg {
                display: block;
            }
        }

        .pagination__prev {
            margin-right: 12px;

            @include breakpoint(tiny) {
                margin-right: 0;
            }

            svg {
                margin-right: 10px;
            }
        }

        .pagination__next {
            margin-left: 12px;

            @include breakpoint(tiny) {
                margin-left: 0;
            }

            svg {
                margin-left: 10px;
            }
        }
