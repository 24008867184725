.back-to-top {
    position: absolute;
    right: $site-gutter;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: $c-white;
    background: $c-black;
    border-radius: 100%;

    @include breakpoint(tablet) {
        right: $site-gutter--mobile;
        top: $site-gutter--mobile;
    }

    &:focus:not(:hover) {
        @include focus(100%, ($c-white, $c-black), 2px);
    }

    &:hover {
        color: $c-black;
        background: $c-white;
    }

    svg { // To be removed once correct icon is received
        width: 7px;
        height: 5px;
        fill: currentColor;
    }
}
