@use 'sass:math';

.footer-cta {
    flex: 1 1 33.3333%;
    background: $c-grey;
    border-radius: 3px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @include breakpoint(tablet) {
        background: transparent;
        flex-basis: 100%;
    }

    @include breakpoint(mobile) {
        grid-template-columns: 1fr 2fr;
    }
}

.footer-cta__image {
    height: 100%;
    position: relative;
    @include aspect(math.div(1, 1));
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    @include breakpoint(mobile) {
        @include aspect(math.div(3, 4));

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    img {
        position: absolute;
        height: 100%;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.footer-cta__content {
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 16px 20px 24px;
    height: 100%;

    @include breakpoint(mobile) {
        padding: 0 22px;
    }
}

.footer-cta__title {
    @include font(18px, 28px);
    flex: 0 0 100%;
    margin-bottom: 10px;

    @include breakpoint(mobile) {
        @include font(16px, 24px);
    }
}

.footer-cta__intro {
    @include font(14px, 22px);
    margin-bottom: 10px;
}
