.mfp-bg {

    &.mfp--light {

        @include breakpoint(phablet) {
            background: $c-white;
            opacity: 1;
        }
    }

    &:not(.mfp--light) {

        @include breakpoint(tablet) {
            background: $c-black;
            opacity: 1;
        }
    }

    &.mfp--newsletter {
        @include breakpoint(tablet) {
            opacity: 0.5;
        }
    }
}

// take the z-index over the admin bar

.mfp--takeover {
    z-index: 999999;

    .mfp-close {
        z-index: 9999991;
    }
}

.mfp-container {
    padding: 0;
}

.mfp-content {
    padding: $site-gutter 0;

    @include breakpoint(tablet) {
        padding: $site-gutter--mobile 0;
    }

    @include breakpoint(phablet) {
        padding: 0;
    }

    .mfp--light & {

        @include breakpoint(phablet) {
            vertical-align: top;
        }
    }

    .mfp--newsletter & {
        @include breakpoint(tablet) {
            vertical-align: bottom;
        }
    }

    .mfp--takeover & {
        padding-top: 0;
    }
}

button.mfp-close {
    top: 13px;
    right: 13px;
    width: $site-gutter;
    height: $site-gutter;
    line-height: $site-gutter;
    border-radius: 100%;
    opacity: 1;

    @include breakpoint(tablet) {
        top: $site-gutter--mobile;
        right: $site-gutter--mobile;
    }

    &:focus:not(:hover) {
        @include focus(100%, $color: $c-white);

        .mfp--light & {
            @include focus(100%, ($c-white, $c-black), 2px);
        }
    }

    &:active {
        top: $site-gutter;

        @include breakpoint(tablet) {
            top: $site-gutter--mobile;
        }
    }

    .mfp-close-btn-in & {
        color: $c-white;

        &.mfp-close--dark {
            color: $c-black;
        }
    }

    .mfp--light & {
        top: $site-gutter;
        right: $site-gutter;
        background: $c-black;
    }

    .mfp--takeover & {
        top: 13px;
        right: 30px;
        background: $c-black;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7px;
        height: 7px;
        transform: translate(-50%, -50%);
        fill: $c-white;
        stroke: $c-white;
        pointer-events: none;
    }
}
