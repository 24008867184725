// $cookie-fontweight: normal;
// $cookie-texttransform: none;
$cookie-bg: $c-background;
$cookie-radius: 25px;
$cookie-fg: $c-white;
// $cookie-nav-bg: #C4D0D6;
$cookie-footer-bg: $c-background;
// $cookie-padding: 10px;
// $cookie-overlay: rgba(0,0,0,0.4);
// $cookie-switch-height: 30px;
$cookie-switch-bg: #e8e6df;
$cookie-switch-fg: $c-black;
$cookie-border: $c-border;

// Variables to reset the layout of the cookie banner container
// Allows alteration of the width to best align with site content
// $cookie-wrappadding should mimic the 'wrap' element padding

// $cookie-banner-maxwidth: 1680px;
// $cookie-gutter-reset: 20px 0 10px 0;
$cookie-wrappadding: 15px;
$cookie-wrappadding-mobile: 15px;
// $cookie-close-marginright: 50px;
// $cookie-close-marginright-mobile: 5px;

// Font colours

$cookie-fontcolor: $c-body;
$cookie-titlecolor: #4A4A4A;
$cookie-altfontcolor: $c-secondary;

// Font sizes

$cookie-fontsize: 13px;
$cookie-h3-fontsize: 30px;
$cookie-h4-fontsize: 20px;
$cookie-copy-fontsize: 16px;

// Buttons

$cookie-btn-letterspacing: normal;
$cookie-btn-texttransform: none;
// $cookie-btn-fontweight: normal;
$cookie-btn: $c-button;
$cookie-btn-bg: $c-button--hover;
$cookie-btn-onhover: $c-button--hover;
$cookie-btn-onhover-bg: $c-button;
// $cookie-btn-border: transparent;

@import "cookie-centre/cookie-settings";
@import "cookie-centre/cookie-onetrust";

#onetrust-banner-sdk .ot-sdk-container {
    margin: 0 auto !important;
    width: $site-width !important;
    max-width: $site-max-width !important;
    padding: 0 $site-gutter !important;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter--mobile !important;
    }
}

.cookie-preferences__panels {
    min-height: 250px;
}
#onetrust-pc-btn-handler:hover {
    text-decoration: underline !important;
}
#onetrust-banner-sdk #onetrust-policy-text a {
    font-weight: normal !important;
    margin: 0 !important;
}
