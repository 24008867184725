.page-title {
    @include page-title;
}

.large-title {
    @include large-title;
}

.medium-title {
    @include medium-title;
}

.small-title {
    @include h4;
}

.large-subtitle {
    @include large-subtitle;
}

.medium-subtitle {
    @include medium-subtitle;
}

.small-subtitle {
    @include small-subtitle;
}

.smallcaps {
    @include smallcaps;
}

.smallprint {
    @include smallprint;
}

.block-title {
    @include h1;
}

.block-title--offset {
    margin-bottom: -28px;
}

.caps {
    text-transform: uppercase;
}
