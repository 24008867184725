@use "sass:math";

.sticky-grid {
    @include breakpoint(tablet) {
        max-width: 670px;
        margin: 0 auto;
    }
}

    .sticky-grid__header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 15px;
    }

        .sticky-grid__title {
            @include medium-title;
        }

        .sticky-grid__link {
            @include font(14px, 16px);
            @include boldText;

            text-decoration: none;
            color: $c-black;

            &:hover {
                text-decoration: underline;
            }
        }

    .sticky-grid__container {
        display: grid;
        grid: auto / repeat(2, minmax(0, 1fr));
        align-items: start;
        gap: 12px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            row-gap: 30px;
        }
    }

        .sticky-grid__sticky-panel {
            position: sticky;
            top: calc(#{$headerHeight + $noticeHeight});

            @include breakpoint(tablet) {
                position: relative;
                top: unset;
            }

            .body--debug & {
                top: calc(#{$headerHeight + $noticeHeight} + 20px);
            }

            .body--admin & {
                top: calc(#{$headerHeight + $noticeHeight} + 44px);
            }

            .body--admin.body--debug & {
                top: calc(#{$headerHeight + $noticeHeight} + 64px);
            }

            .body--debug &,
            .body--admin &,
            .body--admin.body--debug & {
                @include breakpoint(tablet) {
                    top: unset;
                }
            }

            a {
                text-decoration: none;
                color: $c-black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

            .sticky-grid__card-image {
                @include aspect(1);

                margin-bottom: 15px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .sticky-grid__card-title {
                @include responsive-h2;

                margin-bottom: 5px;
            }

            .sticky-grid__card-copy {
                margin-bottom: 15px;
            }

        .sticky-grid__grid {
            display: grid;
            grid: auto / repeat(2, minmax(0, 1fr));
            row-gap: 30px;
            column-gap: 12px;
        }

                .sticky-grid__grid-link {
                    display: block;
                    text-decoration: none;
                    color: $c-black;
                }

                    .sticky-grid__grid-image {
                        @include aspect(math.div(3, 4));

                        margin-bottom: 15px;

                        img {
                            max-width: 100%;
                            height: auto
                        }
                    }

                    .sticky-grid__grid-title {
                        @include font(18px, 24px);
                        padding-bottom: 15px;

                        @include breakpoint(tablet) {
                            @include font(16px, 24px)
                        }
                    }

                .sticky-grid__grid-category {
                    border: solid 1px $c-black;
                    border-radius: 10px;
                    padding: 5px 10px;
                    color: $c-black;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 15px;
                    display: inline-block;
                }
