@import 'tools/mixins/_margin';

.header {
    @include lightondark;
    @include allowForBars;

    position: fixed;
    right: 0;
    left: 0;
    color: $c-black;
    background: $c-white;
    z-index: 1000;

    [header-activating="true"] & {
        @include allowForBars((
            default: -$headerHeight,
            nav: -$headerHeightMobile,
            tablet: -$headerHeightMobile,
            phablet: -$headerHeightMobile
        ));
    }

    [show-notice] & {
        @include allowForBars($noticeHeight, $notice: true);
    }

    [show-two-notice] & {
        @include allowForBars($noticeHeight * 2, $notice: true);
    }

    ~ #main {
        @include allowForBars((
            default: $headerHeight,
            nav: $headerHeightMobile,
            tablet: $headerHeightMobile,
            phablet: $headerHeightMobile
        ), padding-top);

        [show-notice] & {
            @include allowForBars((
                default: $headerHeight + $noticeHeight,
                nav: $headerHeightMobile + $noticeHeight,
                tablet: $headerHeightMobile + $noticeHeight,
                phablet: $headerHeightMobile + $noticeHeight
            ), padding-top, true);
        }
        [show-two-notice] & {
            @include allowForBars((
                default: $headerHeight + $noticeHeight * 2,
                nav: $headerHeightMobile + $noticeHeight * 2,
                tablet: $headerHeightMobile + $noticeHeight * 2,
                phablet: $headerHeightMobile + $noticeHeight * 2
            ), padding-top, true);
        }
    }
}

.header--scrollable {
    transition: color .3s ease, background .3s ease;

    &:not(.header--scroll) {
        background: transparent;
    }

    ~ #main {

        [show-notice] & {
            @include allowForBars((
                default: $noticeHeight,
                nav: $noticeHeight,
                tablet: $noticeHeight,
                phablet: $noticeHeight
            ), padding-top, true);
        }
        [show-two-notice] & {
            @include allowForBars((
                default: $noticeHeight * 2,
                nav: $noticeHeight * 2,
                tablet: $noticeHeight * 2,
                phablet: $noticeHeight * 2
            ), padding-top, true);
        }
    }
}

.header--no-transition {
    transition: none;
}

.header--white {
    color: $c-white;

    [header-active="true"] & {
        color: $c-black;
    }
}

.header--scroll {
    color: $c-black;
}

    .header__wrap {
        @extend .wrap;
        display: flex;
        align-items: center;

        @include breakpoint(nav) {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include breakpoint(tablet) {
            padding-right: $site-gutter--mobile;
            padding-left: $site-gutter--mobile;
        }
    }

        .header__logo {
            margin-right: $site-gutter - 8px;
            margin-left: -8px;
            padding: 8px;

            @include breakpoint(desktop) {
                margin-right: 10px;
            }

            svg {
                display: block;
                fill: $c-black;
                width: 128px;
                height: 14px;
                transition: fill .3s ease;

                .header--no-transition & {
                    transition: none;
                }

                .header--white & {
                    fill: $c-white;
                }

                .header--scroll & {
                    fill: $c-black;
                }
            }
        }

        .header__nav {
            flex: 1 1 auto;
            margin-right: $site-gutter;

            @include breakpoint(desktop) {
                margin-right: 6px;
            }

            @include breakpoint(nav) {
                flex: 0 0 auto;
                margin-right: 0;
            }
        }

        .header__search {
            flex: 0 0 auto;
            margin: 0;

            @include breakpoint(nav) {
                flex: 0 0 auto;
                margin: 0;
            }
        }

        .header__actions-wrapper {

            &[move-id="desktop"] {
                @include margin(right, 25px);
            }

            &[move-id="nav"] {

                @include breakpoint(nav) {
                    flex: 1 1 auto;
                }
            }
        }

            .header__actions {
                display: flex;
                margin-right: -12px;

                @include breakpoint(nav) {
                    justify-content: flex-end;
                }

                > * {
                    margin-right: 8px;
                    display: flex;
                    align-items: center;

                    @include breakpoint(desktop) {
                        margin-right: 4px;
                    }

                    @include breakpoint(nav) {
                        margin-right: 8px;
                    }

                    @include breakpoint(tiny) {
                        margin-right: 0;
                    }
                }
            }

        .header__membership-wrapper {

            &[move-id="nav"] {

                @include breakpoint(nav) {
                    @include margin(bottom, 30px);
                }

                [header-item-active="true"] & {

                    @include breakpoint(nav) {
                        display: none;
                    }
                }
            }
        }

            .header__membership {
                span {
                    @include breakpoint(desktop) {
                        padding-right: 18px;
                        padding-left: 18px;
                    }

                    @include breakpoint(nav) {
                        padding-right: 24px;
                        padding-left: 24px;
                    }
                }

                @include breakpoint(phablet) {
                    width: 100%;
                }
            }

        .header__shipping-wrapper {

            &[move-id="desktop"] {
                @include margin(right, 20px);

                position: absolute;
                top: 0;
                right: 0;
                z-index: 99;

                .header__shipping {
                    padding: 10px 20px 10px 10px;
                }

                a {
                    color: $c-white;
                }

                &:last-child {
                    margin-right: 20px;
                }
            }

            &[move-id="nav"] {

                [header-item-active="true"] & {

                    @include breakpoint(nav) {
                        display: none;
                    }
                }
            }
        }
