@use "sass:math";

.nav-callout {
    @include margin(bottom, 2px);

    @include breakpoint(tablet) {
        margin-bottom: 0;
    }
}

    .nav-callout__link {
        display: block;
        text-decoration: none;
        color: inherit;
        margin: -8px;
        padding: 8px;

        @include breakpoint(tablet) {
            margin: 1px 1px 0;
            padding: 19px #{$site-gutter--mobile - 1px} 20px;
        }

        &:hover {
            text-decoration: none;
        }
    }

        .nav-callout__image {
            @include aspect(math.div(5,3));

            margin-bottom: calc($site-gutter / 2);

            @include breakpoint(tablet) {
                display: none;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .nav-callout__title {
            @include font(22px, 38px);

            .nav-callout__link:hover & {
                text-decoration: underline;
            }

            @include breakpoint(phablet) {
                width: 100%;
                max-width: 320px;
            }
        }

        .nav-callout__cta {
            @include body;
            @include boldText;
        }
