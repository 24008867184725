.space-listing {

    @include breakpoint(tablet) {
        margin-right: -$site-gutter--mobile;
        margin-left: -$site-gutter--mobile;
    }

    .grid--flex & {
        flex: 0 0 auto;
    }

    &__image {
        @include aspect(1);

        margin-bottom: 15px;

        img,
        picture {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    &__content {

        @include breakpoint(tablet) {
            padding-right: $site-gutter--mobile;
            padding-left: $site-gutter--mobile;
        }
    }

    &__title {
        @include responsive-h2;

        margin-bottom: 15px;

        @include breakpoint(phablet) {
            margin-bottom: 10px;
        }
    }

    &__intro {
        margin-bottom: 20px;
    }

    &--wide {
        display: flex;
        justify-content: center;
        background-color: $c-grey;

        @include breakpoint(tablet) {
            margin-right: 0;
            margin-left: 0;
        }

        @include breakpoint(phablet) {
            flex-direction: column;
            background-color: transparent;
        }

        .space-listing__image {
            flex: 1;
            margin-bottom: 0;
            text-align: right;
            @include breakpoint(phablet) {
                margin-bottom: 15px;
            }
        }

        .space-listing__title {
            @include h1;
        }

        .space-listing__content {
            flex: 1;
            max-width: 50%;
            padding-top: 64px;
            padding-right: $site-gutter;
            padding-left: 56px;

            @include breakpoint(phablet) {
                padding-top: 0;
                padding-right: $site-gutter--mobile;
                padding-left: $site-gutter--mobile;
                max-width: none;
            }
        }
    }

    &--wide-reversed {

        @include breakpoint(tablet) {
            margin-right: 0;
            margin-left: 0;
        }

        .space-listing__image {
            order: 2;

            @include breakpoint(phablet) {
                order: 0;
            }
        }

        .space-listing__title {
            @include h1;
        }

        .space-listing__content {
            padding-right: $site-gutter;
            padding-left: 24px * 2;

            @include breakpoint(phablet) {
                padding-right: $site-gutter--mobile;
                padding-left: $site-gutter--mobile;
            }
        }
    }
}
