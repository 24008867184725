@use "sass:math";

.voucher-thumb {
    @include aspect(math.div(115,153));
    position: relative;
}

    .voucher-thumb__inner {
        position: absolute;
        width: calc(100% - 30px);
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $c-grey;
        @include aspect(math.div(130,78));

        border-radius: calc(6% / 10%);

        svg {
            width: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include breakpoint(phablet) {
            left: 0;
            width: 100%;
            top: 0;
            transform: none;
        }
    }
