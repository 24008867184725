section.pdf-page {
    padding: 50px 0;
    background: rgb(204, 204, 204);
}

page {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    padding: 1cm;
}

page[size='A4'] {
    width: 21cm;
    min-height: 29.7cm;
}

.pdf-page__header {
    @include font(12px, 14px);

    display: flex;
    background-color: $c-grey;
    padding: 50px;
    flex-direction: column;
    align-items: center;

    svg {
        margin-bottom: 10px;;
    }
}

@media print {
    body,
    page {
        background: white;
        margin: 0;
        box-shadow: 0;
    }
}
