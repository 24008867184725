@use "sass:math";

.alternating-gallery {
    @include breakpoint(tablet) {
        max-width: 670px;
        margin: 0 auto;
    }
}

    .alternating-gallery__header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 15px;
    }

        .alternating-gallery__title {
            @include medium-title;
        }

        .alternating-gallery__link {
            @include boldText;

            text-decoration: none;
            color: $c-black;

            &:hover {
                text-decoration: underline;
            }
        }

    .alternating-gallery__grid {
        $gap: 12px;

        display: grid;
        grid: auto / repeat(4, minmax(0, 1fr));
        column-gap: $gap;
        row-gap: $gap * 2;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            row-gap: 30px;
        }
    }

    .alternating-gallery__grid--with-callout {
        $gap: 12px;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: $gap;
        row-gap: $gap * 2;
        margin-bottom: 24px;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin-bottom: 30px;
        }
    }

        .alternating-gallery__card {
            a {
                display: block;
                text-decoration: none;
                color: $c-black;
            }

            &.alternating-gallery__card--blog-listing {
                @include breakpoint(tablet) {
                    margin-bottom: 10px;
                }
            }
        }

        .alternating-gallery__card--callout {
            grid-column: span 2;
        }

            .alternating-gallery__image {
                margin-bottom: 10px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .alternating-gallery__image--landscape {
                @include aspect(math.div(4, 3));

                @include breakpoint(tablet) {
                    @include aspect(math.div(3, 4));
                }
            }

            .alternating-gallery__image--portrait {
                @include aspect(math.div(3, 4));
            }

            .alternating-gallery__image--square {
                @include aspect(math.div(1, 1));
            }

            .alternating-gallery__card-title {
                @include font(18px, 24px);
                @include breakpoint(tablet) {
                    @include font(16px, 24px);
                }
                margin-bottom: 10px;
                text-decoration: none;

                .alternating-gallery__card--blog-listing a:hover &,
                .alternating-gallery__card--hub-listing a:hover & {
                    text-decoration: underline;
                }
            }

            .alternating-gallery__card-intro {
                @include font(14px, 22px);
                margin-bottom: 10px;

                @include breakpoint(tablet) {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                }
            }

            .alternating-gallery__card-cta {
                @include font(14px, 16px);
                font-weight: 600;

                a:hover & {
                    text-decoration: underline;
                }
            }
