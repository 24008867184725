@import 'tools/mixins/_margin';

@mixin band_content($background-color, $theme: 'normal') {
    $text-color: null;
    $button-extend: null;
    $button-border-extend: null;

    @if $theme == 'light' {
        $text-color: $c-white;
        $button-extend: '.button--white-hover';
        $button-border-extend: '.button--border-white';
    }

    background-color: $background-color;

    @if $text-color {
        & .block__title,
        & .block__intro *,
        & .block__text * {
            color: $text-color;
        }
    }

    @if $button-extend {
        & .button {
            @extend #{$button-extend};
        }
    }

    @if $button-border-extend {
        & .button--border {
            @extend #{$button-border-extend};
        }
    }
}

.band {
    margin: $band-normal 0;
}

.band--flush {
    margin-top: 0;
    margin-bottom: 0;
}

.band--tight {
    margin-top: $band-tight;
    margin-bottom: $band-tight;

    @include breakpoint(phablet) {
        margin-top: $band-tight-mobile;
        margin-bottom: $band-tight-mobile;
    }
}

.band--tall {
    margin-top: $band-tall;
    margin-bottom: $band-tall;

    @include breakpoint(phablet) {
        margin-top: $band-tall-mobile;
        margin-bottom: $band-tall-mobile;
    }
}

.band--tallest {
    margin-top: $band-tallest;
    margin-bottom: $band-tallest;

    @include breakpoint(phablet) {
        margin-top: $band-tallest-mobile;
        margin-bottom: $band-tallest-mobile;
    }
}

.band--tallest-adapt {
    margin-top: $band-tallest;
    margin-bottom: $band-tallest;

    @include breakpoint(phablet) {
        margin-top: $band-tallest-mobile-adapt;
        margin-bottom: $band-tallest-mobile-adapt;
    }
}

.band--flush-top {
    margin-top: 0;
}

.band--flush-bottom {
    margin-bottom: 0;
}


.band--tight-top {
    margin-top: $band-tight;
}

.band--tight-bottom {
    margin-bottom: $band-tight;
}

.band--ph-normal {
    @include breakpoint(phablet) {
        margin-top: $band-normal;
        margin-bottom: $band-normal;
    }
}

.band--ph-flush-top {
    @include breakpoint(phablet) {
        margin-top: 0;
    }
}

.band--ph-tight-top {
    @include breakpoint(phablet) {
        margin-top: $band-tight;
    }
}

.band--ph-tight-bottom {
    @include breakpoint(phablet) {
        margin-bottom: $band-tight;
    }
}

.band--panel-white {
    position: relative;
    z-index: 0;
    background: $c-white;
    &:before {
        content: "";
        z-index:0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        height: $band-normal;
        background: $c-white;
    }

    &.band--tall:before {
        height: $band-tall;

        @include breakpoint(phablet) {
            height: $band-tall-mobile;
        }
    }
    &.band--tallest:before {
        height: $band-tallest;

        @include breakpoint(phablet) {
            height: $band-tallest-mobile;
        }
    }
}

.band--padded {
    margin: 0;
    padding: $band-tall 0;
}

.band--panel {
    padding-top: $band-tallest;
    padding-bottom: $band-tallest;
    margin-bottom: 0;
    margin-top: 0;

    &:before {
        content: none;
    }

    @include breakpoint(phablet) {
        padding-top: $band-tallest-mobile;
        padding-bottom: $band-tallest-mobile;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.band--panel-adapt {
    padding-top: $band-tallest;
    padding-bottom: $band-tallest;
    margin-bottom: 0;
    margin-top: 0;

    &:before {
        content: none;
    }

    @include breakpoint(phablet) {
        padding-top: $band-tallest-mobile-adapt;
        padding-bottom: $band-tallest-mobile-adapt;
        margin-top: 0;
        margin-bottom: 0;
    }
}


.band--panel-top {
    padding-top: $band-tallest;
    margin-top: 0;

    &:before {
        content: none;
    }

    @include breakpoint(phablet) {
        padding-top: $band-normal;
        margin-top: 0;
    }
}

.band--panel-bottom {
    padding-bottom: $band-tallest;
    margin-bottom: 0;

    @include breakpoint(phablet) {
        padding-bottom: $band-normal;
        margin-bottom: 0;
    }
}

.band--panel-normal {
    padding-top: $band-normal;
    padding-bottom: $band-normal;
    margin-bottom: 0;
    margin-top: 0;

    &:before {
        content: none;
    }
}

.band--grey {
    @include band_content($c-grey);
}

.band--l-grey {
    @include band_content($c-grey);

    @include breakpoint(tablet) {
        @include band_content(transparent);
    }
}

.band--white {
    @include band_content(#ffffff);
}

.band--grey-blue {
    @include band_content(#47535A, 'light');
}

.band--sage {
    @include band_content(#7E7760, 'light');
}

.band--mustard {
    @include band_content(#93693D, 'light');
}

.band--rust {
    @include band_content(#80493D, 'light');
}

.band--center {
    text-align: center;
}

.band--relative {
    position: relative;
}

.band--image {
    @include lightondark;

    position: relative;
    white-space: nowrap;
    overflow: hidden;

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
        padding-bottom: calc(100% / (8 / 3));

        @include breakpoint(tablet) {
            padding-bottom: calc(100% / (5 / 3));
        }
        @include breakpoint(phablet) {
            padding-bottom: 100%;
        }
    }
}

    .band__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;

        img {
            width: 100%;
            height: auto;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($c-black, 0.5);
        }
    }

    .band__content {
        vertical-align: middle;
        display: inline-block;
        white-space: normal;
        width: 100%;
    }

    .band__header {
        @include margin(bottom, 30px);

        max-width: 664px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .band__header--full {
        max-width: none;
    }

    .band__header--semi-tight {
        @include margin(bottom, 15px);
    }

    .band__header--flush {
        margin-bottom: 0;
    }

    .band__header--tight {
        margin-bottom: 5px;
    }

    .band__header--ph-flush {

        @include breakpoint(phablet) {
            margin-bottom: 0;
        }
    }

    .band__header--ph-semi-tight {

        @include breakpoint(phablet) {
            @include margin(bottom, 15px);
        }
    }

    .band__header--ph-tight {

        @include breakpoint(phablet) {
            margin-bottom: 5px;
        }
    }

    .band__header--extra-space {
        @include margin(bottom, $band-tall);

        @include breakpoint(laptop) {
            @include margin(bottom, 40px);
        }

        @include breakpoint(phablet) {
            @include margin(bottom, 30px);
        }
    }

    .band__header--narrow {
        max-width: 460px;
    }

    .band__header--left {
        text-align: left;
        margin-left: 0;
    }

    .band__header--ph-left {

        @include breakpoint(phablet) {
            text-align: left;
        }
    }

    .band__header--m-inset {

        @include breakpoint(tablet) {
            margin-right: $site-gutter--mobile;
            margin-left: $site-gutter--mobile;
        }
    }

        .band__title {
            @include responsive-h2;
            @include margin(bottom, 10px);

            .band--image & {
                color: $c-white;
            }
        }

        .band__title-link {
            @include body;

            color: inherit;
            text-decoration: none;
            margin-left: 7px;
            padding: 0 8px;
            display: inline-block;
        }

        .band__text {
            @include h5;
            max-width: 1000px;

            .band--image & {
                color: $c-band-white-text;
            }
        }

            .band__text--large {
                @include font(18px, 24px);

                @include breakpoint(phablet) {
                    @include font(14px, 20px);
                }
            }

        .band__button {
            margin-top: 20px;

            .button {
                @include margin(right, 10px);
            }
        }
