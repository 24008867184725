.article-preview {}

.article-preview--small {

    @include breakpoint(tablet) {
        margin-bottom: 48px;
    }

    .grid >:last-child > & {

        @include breakpoint(tablet) {
            margin-bottom: 0;
        }
    }
}

    .article-preview__link {
        display: block;
        color: $c-black;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        .article-preview:not(.article-preview--full):not(.article-preview--band-large) & {
            margin: -8px;
            padding: 8px;

            @include breakpoint(tablet) {
                margin: 0;
                padding: 0;
            }
        }

        .article-preview--full &:focus:not(:hover),
        .article-preview--band-large &:focus:not(:hover) {
            box-shadow: none;
            border-radius: 0;
        }

        .article-preview--simple &:focus:not(:hover),
        .article-preview--band-small &:focus:not(:hover),
        .article-preview--small &:focus:not(:hover),
        .article-preview--dual-image &:focus:not(:hover) {

            @include breakpoint(tablet) {
                box-shadow: none;
                border-radius: 0;
            }
        }
    }

        .article-preview__image {
            flex: 0 0 auto;
            margin-bottom: 20px;

            .article-preview--full & {
                margin-bottom: 40px;

                @include breakpoint(tablet) {
                    margin-bottom: 20px;
                }
            }

            .article-preview--band-large & {
                margin-bottom: 0;

                @include breakpoint(tablet) {
                    margin-bottom: 20px;
                }
            }

            img,
            picture {
                width: 100%;
                height: auto;
            }
        }

        .article-preview__content {

            .article-preview--full & {
                max-width: none;
            }

            .article-preview--simple & {

                @include breakpoint(tablet) {
                    padding: 0 $site-gutter--mobile;
                }
            }

            .article-preview--band-large & {
                padding-top: 70px;
            }

            .article-preview--band-small & {
                padding-left: 150px;
                padding-top: 20px;
            }

            .article-preview--band-large &,
            .article-preview--band-small & {
                padding-right: 50px;
                padding-bottom: 20px;

                @include breakpoint(tablet) {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            .article-preview--band-large &,
            .article-preview--band-small &,
            .article-preview--dual-image & {

                @include breakpoint(tablet) {
                    padding-left: $site-gutter--mobile;
                    padding-right: $site-gutter--mobile;
                }
            }

            .article-preview--small & {

                .wrap--m-flush & {

                    @include breakpoint(tablet) {
                        padding-left: $site-gutter--mobile;
                        padding-right: $site-gutter--mobile;
                    }
                }
            }
        }

            .article-preview__content-inner {

                .article-preview--full .article-preview__link &,
                .article-preview--band-large .article-preview__link & {
                    margin: -8px;
                    padding: 8px;
                }

                .article-preview--full .article-preview__link:focus:not(:hover) &,
                .article-preview--band-large .article-preview__link:focus:not(:hover) & {
                    @include focus;
                }

                .article-preview--simple .article-preview__link &,
                .article-preview--band-small .article-preview__link &,
                .article-preview--small .article-preview__link &,
                .article-preview--dual-image .article-preview__link & {

                    @include breakpoint(tablet) {
                        margin: -8px;
                        padding: 8px;
                    }
                }

                .article-preview--simple .article-preview__link:focus:not(:hover) &,
                .article-preview--band-small .article-preview__link:focus:not(:hover) &,
                .article-preview--small .article-preview__link:focus:not(:hover) &,
                .article-preview--dual-image .article-preview__link:focus:not(:hover) & {

                    @include breakpoint(tablet) {
                        @include focus;
                    }
                }
            }

                .article-preview__title {
                    @include font(32px, 38px);

                    @include breakpoint(tablet) {
                        @include font(24px, 30px);
                    }

                    margin-bottom: 5px;

                    .article-preview--band-small &,
                    .article-preview--small & {
                        @include h4;
                    }

                    .article-preview--band-small & {
                        margin-bottom: 10px;

                        @include breakpoint(tablet) {
                            margin-bottom: 5px;
                        }
                    }
                }

                .article-preview__intro {

                    .article-preview--band-small & {
                        @include h5;

                        margin-bottom: 10px;
                    }
                }

                .article-preview__cta {
                    @include body;
                    @include boldText;

                    &:hover {
                        text-decoration: underline;
                    }
                }

.article-preview--simple {
    max-width: 671px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(tablet) {
        max-width: none;
    }
}

.article-preview--full {
    margin-left: auto;
    margin-right: auto;
}

.article-preview--dual-image {
    margin-left: auto;
    margin-right: auto;
    padding-left: $site-gutter;
    padding-right: $site-gutter;

    @include breakpoint(tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}

.article-preview--band-large {
    margin-left: auto;
    margin-right: auto;

    .grid-item:first-child {
        order: 1;
    }

    .band--grey {

        @include breakpoint(tablet) {
            background-color: transparent;
        }
    }
}

.article-preview--band-small {
    margin-left: auto;
    margin-right: auto;
    padding-left: $site-gutter;
    padding-right: $site-gutter;

    @include breakpoint(tablet) {
        padding-left: 0;
        padding-right: 0;

        .grid-item:first-child {
            order: 1;
        }
    }
}
