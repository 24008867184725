:root {
    --noticeLineHeight: 18px;
    --noticePadding: 22px;
    --noticeLineCount: 0;
}

.notice {
    @include lightondark;
    @include allowForBars;
    @include notice;

    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    min-height: $noticeHeight;
    padding: 11px 5px;
    color: $c-white;
    background: $c-black;
    text-align: center;
    z-index: 12;
    max-width: 100vw;

    [show-notice-loading] & {
        display: block;
        visibility: hidden;
    }

    [show-notice] & {
        display: block;
    }

    [show-two-notice] & {
        @include allowForBars((
            default: $noticeHeight,
            tablet: $noticeHeight,
            phablet: $noticeHeight
        ), top, true);
    }
}
    .notice__content {
        display: flex;
        width: calc(100% - 240px); // minus ship-to width
        margin: auto;

        @include breakpoint(nav) {
            width: 100%;

            & .slick-list {
                & .slick-track {
                    & .slick-slide {
                        margin: auto;
                    }
                }
            }
        }
    }

        .notice__item {
            @include notice;

            flex: 1 1 auto;
            text-align: center;

            &:not(:first-child) {

                @include breakpoint(phablet) {
                    display: none;
                }
            }

            &:not(:first-child):not(:nth-child(2)) {

                @include breakpoint(tablet) {
                    display: none;
                }
            }

            a {
                margin: 0 -8px;
                padding: 0 8px;
                color: inherit;
                text-decoration: none;
            }

            .site-notice--scrolling & {
                display:none;
            }
        }
