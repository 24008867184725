:root {
    --noticeLocaleLineHeight: 18px;
    --noticeLocalePadding: 22px;
    --noticeLocaleLineCount: 0;
}

.notice-locale {
    @include allowForBars;
    @include notice;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    min-height: $noticeHeight;
    padding: 11px 0;
    color: $c-black;
    background: $c-white;
    text-align: center;
    z-index: 11;

    & .notice-locale__content {
        display: flex;
        padding: 0 60px;

        &  .notice-locale__item {
            @include notice;
            flex: 1 1 auto;
            text-align: center;
        }

        & .notice-locale__form {
            display:inline;

            & .notice-locale__button {
                background-color: transparent;
                padding: 0 5px;
                text-decoration: underline;
            }
        }
    }

    & .notice-locale__close {
        background-color:$c-black;
        color:$c-white;
        top: 8px !important;
    }
}

.notice-locale--hidden {
    display:none;
}
