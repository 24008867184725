@use "sass:math";
@import 'tools/mixins/_margin';
@import 'tools/mixins/banner-gradient';

.hero {
    position: relative;

    .header--white ~ #main & {

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100px;
            background: linear-gradient(180deg, $c-black 0%, rgba($c-black, 0) 100%);
            opacity: 0.5;
            z-index: 1;
        }
    }
}

    .hero__video {
        @include aspect(math.div(16,9));

        position: relative;

        @include breakpoint(phablet) {
            @include margin(bottom, 15px);
        }

        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .hero__video--mobile + .hero__video {
        @include breakpoint(phablet) {
            display: none;
        }
    }

    .hero__video--mobile {
        display: none;
        @include breakpoint(phablet) {
            display: block;
            @include aspect(math.div(1,1));
        }
    }

        .hero__play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            color: $c-white;

            svg {
                display: block;
            }

            span {
                margin-top: 8px;
                display: block;
                text-align: center;

                @include breakpoint(phablet) {
                    margin-top: 3px;
                }
            }
        }

        .hero__video-preview {
            display: block;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

    .hero__picture {
        @include aspect(math.div(5,3));

        @include breakpoint(phablet) {
            @include aspect(math.div(1,1));
            @include margin(bottom, 25px);
        }

        img {
            width: 100%;
            height: auto;
            max-width: none;
        }
    }

    .hero__content {
        position: absolute;
        color: $c-white;
        pointer-events: none;

        a {
            pointer-events: all;
        }

        @include breakpoint(phablet) {
            position: static;
            color: inherit;
        }

        .hero--simple & {
            position: static;
            bottom: 0;
            color: inherit;
        }
    }

    .hero .hero__content {

        .button {
            margin-right: 10px;
        }

        @include breakpoint(tablet) {
            .hero__title {
                @include font(42px, 46px);
            }


            .button {
                @include breakpoint(phablet) {
                    color: $c-white;
                    background-color: $c-black;
                    border: 1px solid $c-black;

                    &:hover,
                    &:active {
                        color: $c-black;
                        background-color: $c-white;
                    }
                }
            }

            @include breakpoint(phablet) {
                .button.button--transparent {
                    background: transparent;
                    color: $c-black;

                    &:hover,
                    &:active {
                        color: $c-white;
                        background-color: $c-black;
                    }

                    &.btn-alt {
                        color: $c-white;

                        &:hover,
                        &:active {
                            color: $c-black;
                            background-color: $c-white;
                        }
                    }
                }
            }
        }
    }

    .hero.hero--text-centred .hero__content {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .hero__intro {
            margin-left: auto;
            margin-right: auto;
        }

        @include breakpoint(phablet) {
            transform: unset;
            text-align: unset;
            max-width: unset;
            .hero__intro {
                margin-left: unset;
                margin-right: unset;
            }
        }
    }

    .hero.hero--text-left-centred .hero__content {
        top: 50%;
        left: 0;
        transform: translate(0%, -50%);
        max-width: 50%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
        }
    }

    .hero.hero--text-right-centred .hero__content {
        top: 50%;
        left: unset;
        right: 0;
        transform: translate(0%, -50%);
        max-width: 50%;

        text-align: right;

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
            text-align: left;
        }

        .hero__intro {
            margin-left: auto;
            margin-right: 0;

            @include breakpoint(phablet) {
                margin-left: unset;
                margin-right: unset;
            }
        }
    }

    .hero.hero--text-centred-bottom .hero__content {
        top: unset;
        left: 50%;
        bottom: 50px;
        transform: translate(-50%, 0%);
        text-align: center;
        max-width: 75%;

        .hero__intro {
            margin-left: auto;
            margin-right: auto;
        }

        @include breakpoint(phablet) {
            transform: unset;
            text-align: unset;
            max-width: unset;
            .hero__intro {
                margin-left: unset;
                margin-right: unset;
            }
        }
    }

    .hero.hero--text-left-bottom .hero__content {
        bottom: 50px;
        left: 0;
        max-width: 50%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
        }
    }

    .hero.hero--text-right-bottom .hero__content {
        left: unset;
        right: 0;
        bottom: 50px;
        max-width: 50%;

        text-align: right;

        @include breakpoint(tablet) {
            max-width: 100%;
        }

        @include breakpoint(phablet) {
            transform: unset;
            max-width: unset;
            text-align: left;
        }

        .hero__intro {
            margin-left: auto;
            margin-right: 0;

            @include breakpoint(phablet) {
                margin-left: unset;
                margin-right: unset;
            }
        }
    }

    .hero.hero--size-short .hero__picture,
    .hero.hero--size-short .hero__video {
        @include aspect(math.div(2,1));
    }

    .hero__content--video {
        z-index: 11;
        bottom: 70px;
    }

        .hero__title {
            @include page-intro-title;
            @include lightondark();
            @include margin(bottom, 10px);

            @include breakpoint(phablet) {
                @include margin(top, 15px);
            }

            .hero--simple & {
                @include page-intro-title;
                @include margin(bottom, 17px);
            }
        }

        .hero__intro {
            @include h5;
            @include margin(bottom, 20px);

            max-width: 500px;

            .hero--simple & {
                @include page-intro-subtitle;
                @include margin(bottom, 20px);

                max-width: 795px;

                a {
                    margin: -4px;
                    padding: 4px;
                    color: $c-brand;
                }
            }
        }


    .hero--light-on-dark {
        color: $c-white;

        @include breakpoint(phablet) {
            color: $c-black;
        }
    }

    .hero--dark-on-light {
        color: $c-black;
    }

    .hero--gradient-dark {
        position: relative;

        .hero--text-centred & picture {
            @include bannerGradient($c-black, $banner-gradient__text-position--c);
        }

        .hero--text-left-centred & picture {
            @include bannerGradient($c-black, $banner-gradient__text-position--cl);
        }

        .hero--text-left-bottom & picture {
            @include bannerGradient($c-black, $banner-gradient__text-position--bl);
        }

        .hero--text-right-centred & picture {
            @include bannerGradient($c-black, $banner-gradient__text-position--cr);
        }

        .hero--text-right-bottom & picture {
            @include bannerGradient($c-black, $banner-gradient__text-position--br);
        }

        .hero--text-centred-bottom & picture {
            @include bannerGradient($c-black, $banner-gradient__text-position--bc);
        }
    }

    .hero--gradient-light {
        position: relative;

        .hero--text-centred & picture {
            @include bannerGradient($c-white, $banner-gradient__text-position--c);
        }

        .hero--text-left-centred & picture {
            @include bannerGradient($c-white, $banner-gradient__text-position--cl);
        }

        .hero--text-left-bottom & picture {
            @include bannerGradient($c-white, $banner-gradient__text-position--bl);
        }

        .hero--text-right-centred & picture {
            @include bannerGradient($c-white, $banner-gradient__text-position--cr);
        }

        .hero--text-right-bottom & picture {
            @include bannerGradient($c-white, $banner-gradient__text-position--br);
        }

        .hero--text-centred-bottom & picture {
            @include bannerGradient($c-white, $banner-gradient__text-position--bc);
        }
    }
