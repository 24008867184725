.reveal {}

.reveal--active {
    position: relative;
    max-height: 6 * 22px;
    overflow: hidden;

    &.reveal--large {
        max-height: 6 * 24px;
    }

    &.reveal--small {
        max-height: 6 * 20px;
    }

    &.reveal--m-small {

        @include breakpoint(tablet) {
            max-height: 6 * 20px;
        }
    }

    &.reveal--m-normal {

        @include breakpoint(tablet) {
            max-height: 6 * 22px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba($c-white, 0) 0%, $c-white 100%);
    }

    &[revealing="true"]:after {
        opacity: 0;
        transition: opacity .3s ease;
    }
}

.revealer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 66px;
}

    .revealer__button {
        width: 44px;
        height: 44px;
        padding: 0;
        background: transparent;
        border: 1px solid $c-black;
        border-radius: 100%;

        &:focus:not(:hover) {
            @include focus(100%, ($c-white, $c-black), 2px);
        }
    }

        .revealer__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
