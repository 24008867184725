.precision-alignment {
    display: inline-flex;
}

    .precision-alignment__item {
        margin-top: -2px;

        .precision-alignment--small & {
            margin-top: -1px;
        }
    }

.precision-alignment--uppercase,
.precision-alignment--uppercase .precision-alignment__item {
    text-transform: uppercase;
}
