.character-count {
    position: relative;

    .input {
        display: block;
    }
}

    .character-count__display {
        @include font(14px, 14px);

        position: absolute;
        right: 0;
        bottom: 0;
        padding: 11px 16px;
        color: $c-body;
        background: $c-background;
        border-radius: 4px;
        margin-bottom: 1px;
    }
