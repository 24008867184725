.article {}

    .article__header {
        margin-left: auto;
        margin-right: auto;
        padding-left: $site-gutter;
        padding-right: $site-gutter;
        margin-bottom: 80px;

        @include breakpoint(phablet) {
            padding-left: $site-gutter--mobile;
            padding-right: $site-gutter--mobile;
            margin-bottom: 56px;
        }
    }

    .article__title {
        @include page-intro-title;
    }

    .article__intro {
        @include font(40px, 48px);

        @include breakpoint(phablet) {
            @include font(24px, 34px);
        }

        .article__title + & {
            margin-top: 46px;

            @include breakpoint(phablet) {
                margin-top: 40px;
            }
        }
    }

    .article__meta {
        @include body;

        margin-top: 30px;
        color: $c-article-meta;

        @include breakpoint(phablet) {
            margin-top: 10px;
        }

        > * {
            @include margin(right, 20px);
        }

        a {
            margin: -8px;
            padding: 8px;
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .article__related-articles-header {
        padding-bottom: 10px;
    }

    .article__content {

        .block {
            margin: 80px 0;

            @include breakpoint(phablet) {
                margin: 55px 0;
            }
        }
    }
