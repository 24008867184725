html {
    font-size: 100%;
    height: 100%;
    text-size-adjust: 100%;
}

body {
    @include font(14px, 22px);
    @include apply-map($f-primary);

    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $c-background;
    color: $f-primary-color;
    min-width: 320px;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b, strong {
    @include boldText;
}

html #debug-bar {
    width: 100%;
    z-index: 30;
    height: 20px;
    position: fixed;
    top: 0 !important;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

html #debug-holder {
    display: none !important;
}

.body--debug.body--admin {
    #debug-bar {
        top: 44px !important;
    }
}

html #admin-bar {
    position: fixed;
    top: 0;

    @include breakpoint(phablet) {
        display: none;
    }
}
#admin-bar a {
    line-height: 1;
}

#main {
    outline: none;
    min-height: calc(100vh - 408px);

    .body--enclosed & {
        min-height: calc(100vh - 108px);
    }
    .body--enclosed.body--debug & {
        min-height: calc(100vh - 128px);
        @include breakpoint(tablet) {
            min-height: calc(100vh - 108px);
        }
    }
    .body--enclosed.body--debug.body--admin & {
        min-height: calc(100vh - 172px);
    }
}

.inner-wrapper {
    height: 100%;
}
