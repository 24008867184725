@import 'tools/mixins/_bold-text';
@import 'tools/mixins/_focus';
@import 'tools/mixins/_remove-white-space';

/* start buttons */
.button {
    @include lightondark;
    @include remove-white-space;

    display: inline-block;
    line-height: 1.3;
    padding: 0;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    color: $c-black;
    background: $c-button;
    border-radius: $buttonBorderRadius;
    -webkit-font-smoothing: antialiased; /*make it look nice in webkit*/
    text-align: center;
    white-space: nowrap;

    a:focus:not(:hover) &,
    &:focus:not(:hover),
    &.button--focus:not(:hover),
    input:focus:not(:hover) + &.button--radio {
        @include focus($buttonBorderRadius, ($c-white, $c-black), 2px);

        color: $c-white;
        background-color: $c-button--hover;

        [data-background="dark"] & {
            @include focus($buttonBorderRadius, ($c-black, $c-white), 2px);
        }
    }

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active,
    input:checked + &.button--radio {
        color: $c-white;
        background-color: $c-button--hover;
    }

    &[disabled],
    &.button--disabled {
        color: $c-button--disabled;
        background: $c-button;
        cursor: default;

        [data-background="dark"] & {
            color: $c-black;
            background-color: $c-button--disabled;
        }
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0px;
        height: 40px;
    }

    span {
        @include apply-map($f-button);
        @include compact-body;
        @include boldText;

        display: inline-flex;
        height: 100%;
        padding: 11px 24px;
        vertical-align: middle;
        white-space: normal;
    }
}

button {
    width: auto;
    white-space: nowrap;
    vertical-align: top;
    border: none;
}

.button--brand {
    color: $c-white;
    background: $c-brand;

    &[disabled],
    &.button--disabled {
        color: $c-white;
        background: rgba($c-brand, 0.5);

        [data-background="dark"] & {
            color: $c-white;
            background: rgba($c-brand, 0.5);
        }
    }
}

.button--grey {
    color: $c-black ;
    background: $c-button--grey;

    &[disabled],
    &.button--disabled {
        color: $c-white;
        background: rgba($c-brand, 0.5);

        [data-background="dark"] & {
            color: $c-white;
            background: rgba($c-brand, 0.5);
        }
    }
}

.button--alt {
    color: $c-white;
    background: $c-button--alt;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        color: $c-black;
        background-color: $c-button--alt--hover;
    }

    &[disabled],
    &.button--disabled {
        color: $c-white;
        background-color: $c-button--disabled;

        [data-background="dark"] & {
            color: $c-button--disabled;
            background-color: $c-black;
        }
    }
}

.button--link {
    background-color: transparent;
    display: inline-flex;
    text-underline-offset: 3px;

    span {
        @include font(12px, 24px);

        padding: 0;
    }

    &:after {
        display: none;
    }

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active,
    a:focus:not(:hover) &,
    &:focus:not(:hover) {
        color: $c-black;
        background-color: transparent;
        box-shadow: none;
    }

    &.button--has-icon {
        gap: 6px;
        align-items: center;

        svg {
            margin-left: 0;
            margin-top: 1px;
        }
    }
}

.button--white-hover {
    color: $c-white;
    background: inherit;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        color: $c-black;
        background-color: $c-white;
    }

    &[disabled],
    &.button--disabled {
        color: $c-white;
        background-color: $c-button--disabled;

        [data-background="dark"] & {
            color: $c-button--disabled;
            background-color: $c-black;
        }
    }
}

.button--transparent {
    background: transparent;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {

        [data-background="dark"] & {
            color: $c-black;
            background-color: $c-button--alt--hover;
        }
    }

    &[disabled],
    &.button--disabled {
        background: transparent;

        [data-background="dark"] & {
            color: $c-button--disabled;
            background: transparent;
        }
    }

    [data-background="dark"] & {
        color: $c-white;
    }
}

.button--underline-hover:hover span {
    text-decoration: underline;
}

.button--grey {
    background: $c-button--grey;
}

// Border variations
.button--border {
    border: 1px solid $c-black;

    [data-background="dark"] & {
        border-color: $c-white;
    }

    &[disabled],
    &.button--disabled {
        border-color: $c-button--disabled;
    }

    a:focus:not(:hover) &,
    &:focus:not(:hover),
    &.button--focus:not(:hover),
    input:focus:not(:hover) + &.button--radio {
        border-color: transparent;
    }

    span {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &:after {
        height: 38px;
    }
}

// Border variations
.button--border-normal {
    border: 1px solid $c-black;

    [data-background="dark"] & {
        border-color: $c-white;
    }

    &[disabled],
    &.button--disabled {
        border-color: $c-button--disabled;
    }

    a:focus:not(:hover) &,
    &:focus:not(:hover),
    &.button--focus:not(:hover),
    input:focus:not(:hover) + &.button--radio {
        border-color: transparent;
    }
}

// Border variations
.button--border-white {
    border: 1px solid $c-white;

    [data-background="dark"] & {
        border-color: $c-black;
    }

    &[disabled],
    &.button--disabled {
        border-color: $c-button--disabled;
    }

    a:focus:not(:hover) &,
    &:focus:not(:hover),
    &.button--focus:not(:hover),
    input:focus:not(:hover) + &.button--radio {
        border-color: transparent;
    }
}

.button--border.button--alt  {
    border: 1px solid $c-white;

    [data-background="dark"] & {
        border-color: $c-black;
    }
}

// Content variations
.button--icon {
    background: transparent;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        background-color: transparent;
    }

    &[disabled],
    &.button--disabled {
        background-color: transparent;
        opacity: 0.2;
    }

    a:focus:not(:hover) &,
    &:focus:not(:hover),
    &.button--focus:not(:hover) {
        margin: -8px;
        padding: 8px;

        [data-background="dark"] & {
            @include focus($borderRadius: $buttonBorderRadius, $color: $c-white);
        }
    }

    [data-background="dark"] & {
        color: $c-white;
    }

    &:after {
        display: none;
    }

    svg {
        display: block;
        fill: $c-black;

        [data-background="dark"] & {
            fill: $c-white;
        }
    }
}

.button--has-icon {

    &:after {
        display: none;
    }

    span {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
    }

        svg {
            display: block;
            margin-left: 25px;
            fill: currentColor;
        }

    &.button--full {

        span {
            width: 100%;
        }

            svg {
                margin-left: 15px;
            }
    }
}

// Size variations
.button--small {

    span {
        @include h6;

        padding-top: 5px;
        padding-right: 18px;
        padding-bottom: 5px;
        padding-left: 18px;
    }

    &:after {
        height: 26px;
    }

    &.button--border {

        span {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        &:after {
            height: 24px;
        }
    }
}

.button--full {
    width: 100%;

    span {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.button--ph-full {

    @include breakpoint(phablet) {
        width: 100%;
    }
}

.button--m-circle {
    @include breakpoint(tablet) {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        span {
            padding: 0;
        }

        svg {
            margin: 0;
        }
    }
}

.button--flush {
    width: 100%;
}

// Shape variations
.button--straight {
    border-radius: 0;
}

// Complex variations
.button--straight-and-flush {
    @extend .button--straight;
    @extend .button--flush;

    a:focus:not(:hover) &,
    &:focus:not(:hover),
    &.button--focus:not(:hover) {
        @include focus(0, ($c-black, $c-white), 2px, true);
    }
}

button::-moz-focus-inner{padding:0;border:none;} /* FF Fix */

.button--responsive {
    @include breakpoint(tablet) {
        background: $c-button--alt;
        color: $c-white;

        &[disabled],
        &.button--disabled {
            color: $c-white;
            background-color: $c-button--disabled;

            [data-background="dark"] & {
                color: $c-button--disabled;
                background-color: $c-black;
            }
        }

        a:focus:not(:hover) &,
        &:focus:not(:hover),
        &.button--focus:not(:hover),
        input:focus:not(:hover) + &.button--radio {
            border-color: transparent;
        }

        span {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &:after {
            height: 38px;
        }

        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            color: $c-black;
            background-color: $c-button--alt--hover;
        }
    }
}
