@import 'tools/mixins/_font';
@import 'tools/mixins/_focus';
@import 'tools/mixins/_bold-text';
@import 'tools/mixins/_regular-text';

/* ======================= */
/* = Richtext stylesheet = */
/* ======================= */

/* This should only apply to the wysiwyg */
body.richtext {
    font-size: 100%;
    background: #FFF;
}

.richtext {
    @include apply-map($f-primary);
    @include body;

    color: $c-body;

    h1 {
        @include h1;
    }

    h2 {
        @include h2;
    }

    h3 {
        @include h3;
    }

    h4 {
        @include h4;
    }

    h5 {
        @include h5;
    }

    h6 {
        @include h6;
    }

    h1, h2, h3, h4,  h5, h6 {
        margin-bottom: 0.5em;
    }

    p {
        margin-bottom: calc(10em / 16);
    }

    strong {
        @include boldText;
    }

    blockquote {
        border: 1px solid #BBB;
        background-color: #EEE;
        padding: 1em 2em;
        margin: 0.5em 0 1em 0;
        font-style: italic;
        font-family: Georgia, Times, serif;
        line-height: 1.4;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
        margin-bottom: calc(30em / 16);
    }

    ul ul,
    ol ol {
        margin-top: .5em;
    }

    ul {
        list-style: none;
        padding-left: 20px;
        position: relative;

        li, a {
            margin: -4px 0;
        }

        li:before {
            content: "\2022";
            position: absolute;
            left: 0;
            color: $c-brand;
        }
    }

    ol {
        list-style: decimal outside;
        padding-left: 20px;
    }

    ol ol {
        list-style-type: lower-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    li {
        margin: 0 0 0.75em;
    }

    ul li ,
    ol li {
        padding-left: 3px;
    }

    dl {
        margin: 0 2em 1em 2.5em;
    }

    dt {
        font-weight: bold;
        margin: 1em 0 0.4em 0;

    }

    dd {
        line-height: 1.3em;
        margin-bottom: 0.5em;
    }

    a {
        @include boldText;

        margin: -4px;
        padding: 4px;
        color: $c-brand;
        text-decoration: none;

        &:hover {
            text-decoration: underline;

        }
    }

    hr {
        border: 0;
        height: 1px;
        background-color: $c-border;
        margin: 1.875em 0;
    }

    blockquote {
        margin: 0 0 1.875em;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.richtext--center {
    text-align: center;
}

.richtext--document {
    h1, h2, h3, h4,  h5, h6 {
        margin-bottom: 0.5em;
        margin-top: calc(30em / 16);

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        margin-bottom: 0.8em;
    }

    ul li:before {
        color: $c-body;
    }
}

.richtext--large {
    @include h4;

    p {
        margin-bottom: calc(24em / 18);
    }
}

.richtext--small {
    @include body;

    p {
        margin-bottom: calc(10em / 14);
    }
}

.richtext--m-small {

    @include breakpoint(tablet) {
        @include body;
    }

    p {

        @include breakpoint(tablet) {
            margin-bottom: calc(10em / 14);
        }
    }
}

.richtext--m-normal {

    @include breakpoint(tablet) {
        @include h5;
    }

    p {

        @include breakpoint(tablet) {
            margin-bottom: calc(10em / 16);
        }
    }
}

.richtext--flush {

    >:last-child {
        margin-bottom: 0;
    }
}
