@mixin image-responsive {
    width: 100%;
    height: auto;
}

img.image-responsive,
picture.image-responsive {
    @include image-responsive;
}
img[data-src] {
    transition: opacity 333ms cubic-bezier(.4,0,.22,1);
}
img.lazy {
    opacity: 0;
}
img.lazyloaded {
    opacity: 1;
}
