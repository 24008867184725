/// Change the styles for the placeholder text in an input
///
/// @author Joe Adcock <joe@d3r.com>

@mixin placeholderStyles {

    &::placeholder {
        @content;

        opacity: 1;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-ms-input-placeholder {
        @content;
    }
}
