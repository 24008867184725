@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Regular.woff2') format('woff2'),
         url('/fonts/HKGrotesk-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Medium.woff2') format('woff2'),
         url('/fonts/HKGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Bold.woff2') format('woff2'),
         url('/fonts/HKGrotesk-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Cardo';
    src: url('/fonts/Cardo-Regular.woff2') format('woff2'),
         url('/fonts/Cardo-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}
