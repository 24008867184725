@import 'tools/mixins/grid';
@import 'tools/mixins/grid-columns';
@import 'tools/mixins/grid-column';

$gridRow: 2;
$maxItems: 5;

$linkHeight: calc(26 / $gridRow);
$linkHeightMobile: calc(18 / $gridRow);
$linkMargin: calc(0 / $gridRow);
$linkMarginMobile: calc(12 / $gridRow);

.footer-nav {
    flex: 1 1 66.6666%;
    display: flex;

    @include breakpoint(tablet) {
        @include grid;
        @include gridColumns(1fr 1fr);
        column-gap: 36px;
    }

    > * {

        &[nav-group="1"] {
            -ms-grid-row: 1;
        }

        &[nav-group="2"] {
            -ms-grid-row: 2;
        }

        &[nav-group="3"] {
            -ms-grid-row: 1;
        }

        &[nav-group="4"] {
            -ms-grid-row: 2;
        }

        &[nav-group="5"] {
            -ms-grid-row: 3;
        }
    }
}

    .footer-nav__list {
        flex: 0 0 auto;
        width: 25%;
        padding-right: $site-gutter;

        @include breakpoint(tablet) {
            flex: 0;
            width: auto;
            padding-right: 0;
            margin-bottom: 28px;
        }
    }

        .footer-nav__item {

            @include breakpoint(tablet) {
                display: flex;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

            .footer-nav__link {
                @include font(16px, 26px);

                position: relative;
                display: inline-block;
                margin: 0 -8px;
                padding: 0 8px;
                color: inherit;
                text-decoration: none;

                @include breakpoint(tablet) {
                    @include font(14px, 18px);
                }
            }
