.cta {
    font-family: $f-primary-stack;
    line-height: 1.3;
    text-transform: none;
    font-weight: 400;
    letter-spacing: normal;

    @include font(14px, 22px);
    @include boldText;

    display: inline-block;
    padding: 0;
    color: $c-brand;
    background: transparent;
    text-decoration: none;

    &.cta--hover,
    &:hover,
    a:hover & {
        text-decoration: underline;
    }

    &:focus {
        margin: -4px;
        padding: 4px;
    }

    &[disabled] {
        pointer-events: none;
    }
}

.cta--inherit {
    color: inherit;
}
