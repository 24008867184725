.sticky-popup {
    display: none;
    position: fixed;
    bottom: 10px;
    left: 10px;
    padding: 15px;
    background-color: $c-grey;
    min-width: 400px;
    max-width: 400px;
    opacity: 0;
    z-index: 30;

    @include breakpoint(mobile) {
        bottom: 0;
        right: 0;
        left: 0;
        min-width: 0;
        max-width: none;
    }
}

.sticky-popup--promo {
    padding: 20px 24px 24px;

    @include breakpoint(mobile) {
        padding: 20px 15px 15px;
    }
}

.sticky-popup--black {
    background-color: $c-black;
    color: $c-white;
}

.sticky-popup--active-a {
    opacity: 0;
    display: block;
    transition: opacity 0.3s ease-in-out;
}

.sticky-popup--active-b {
    opacity: 1;
}

    .sticky-popup__sentinel {
        position: absolute
    }

    .sticky-popup__close {
        background-color: transparent;
        padding: 0;
        position: absolute;
        height: 40px;
        width: 40px;
        right: 0;
        top: 0;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            transform: translate(-50%, -50%);
            fill: $c-black;

            .sticky-popup--black &  {
                fill: $c-white;
            }
        }

        &:hover {
            background-color: transparent;
        }
    }

    .sticky-popup__title {
        @include medium-subtitle;
        color: inherit;
    }

    .sticky-popup__message {
        padding-right: 26px;
        margin-top: 10px;
        @include rem(16px);

        @include breakpoint(mobile) {
            padding-right: 0;
        }
    }

    .sticky-popup__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        border-bottom: 1px solid $c-black;

        .sticky-popup--black &  {
            border-bottom-color: $c-white;
        }

        .sticky-popup__controls--error &  {
            border-bottom-color: $c-error;
        }
    }

        .sticky-popup__input {
            @include body;

            padding-right: 20px;
            padding-bottom: 0;
            width: 100%;
            background-color: transparent;
            border: none;

            @include placeholderStyles {
                color: rgba($c-black, 0.5);
            }

            .sticky-popup--black &  {
                @include placeholderStyles {
                    color: rgba($c-white, 0.5);
                }
            }
        }

        .sticky-popup__submit {
            background-color: transparent;
            padding: 0;
            width: 40px;
            height: 40px;
            margin-right: -15px;

            &:hover {
                background-color: transparent;
            }

            svg {
                fill: $c-black;

                .sticky-popup--black &  {
                    fill: $c-white;
                }
            }
        }

    .sticky-popup__error {
        @include rem(14px);

        color: $c-error;
        padding: 5px 15px 5px 0;
    }

    .sticky-popup__button.sticky-popup__button {
        margin-top: 15px;
    }
