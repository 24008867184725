$banner-gradient__text-position--cl: 'cl'; // centered-left
$banner-gradient__text-position--cr: 'cr'; // centered-right
$banner-gradient__text-position--bl: 'bl'; // bottom-left
$banner-gradient__text-position--br: 'br'; // bottom-right
$banner-gradient__text-position--bc: 'bc'; // bottom-center
$banner-gradient__text-position--c: 'c'; // centered

@mixin bannerGradient($colour, $positioning, $opacity: 0.4) {
    $gradientFromLeft: linear-gradient(to right, rgba($colour, $opacity), transparent);
    $gradientFromRight: linear-gradient(to left, rgba($colour, $opacity), transparent);
    $gradientFromBottom: linear-gradient(to top, rgba($colour, $opacity), transparent);

    $gradientWidth: 50%;
    $gradientHeight: 50%;

    @if $positioning == $banner-gradient__text-position--c {
        &:before {
            content: '.';
            position: absolute;

            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background: rgba($colour, 0.25);

            @include breakpoint(phablet) {
                content: none;
            }
        }
    }
    @else if $positioning == $banner-gradient__text-position--bc {
        &:before {
            content: '.';
            position: absolute;

            bottom: 0;
            left: 0;
            right: 0;
            height: $gradientHeight;

            background: $gradientFromBottom;

            @include breakpoint(phablet) {
                content: none;
            }
        }
    }
    @else if $positioning == $banner-gradient__text-position--cl {
        &:before {
            content: '.';
            position: absolute;

            bottom: 0;
            left: 0;
            top: 0;
            width: $gradientWidth;

            background: $gradientFromLeft;

            @include breakpoint(phablet) {
                content: none;
            }
        }
    }
    @else if $positioning == $banner-gradient__text-position--cr {
        &:before {
            content: '.';
            position: absolute;

            bottom: 0;
            right: 0;
            top: 0;
            width: 50%;

            background: $gradientFromRight;

            @include breakpoint(phablet) {
                content: none;
            }
        }
    }
    @else if $positioning == $banner-gradient__text-position--bl {
        &:before {
            content: '.';
            position: absolute;

            bottom: 0;
            left: 0;
            top: 0;
            width: $gradientWidth;

            background: $gradientFromLeft;

            @include breakpoint(phablet) {
                content: none;
            }
        }

        &:after {
            content: '.';
            position: absolute;

            bottom: 0;
            right: 0;
            left: 0;
            height: $gradientWidth;

            background: $gradientFromBottom;

            @include breakpoint(phablet) {
                content: none;
            }
        }
    }
    @else if $positioning == $banner-gradient__text-position--br {
        &:before {
            content: '.';
            position: absolute;

            bottom: 0;
            right: 0;
            top: 0;
            width: $gradientWidth;

            background: $gradientFromRight;

            @include breakpoint(phablet) {
                content: none;
            }
        }

        &:after {
            content: '.';
            position: absolute;

            bottom: 0;
            right: 0;
            left: 0;
            height: $gradientHeight;

            background: $gradientFromBottom;

            @include breakpoint(phablet) {
                content: none;
            }
        }
    }
}
