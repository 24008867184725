.header-links {}

    .header-links__list {
        display: flex;

        @include breakpoint(nav) {
            display: block;
        }
    }

        .header-links__item {
            @include margin(right, $site-gutter);
        }

            .header-links__link {
                @include font(14px, 18px);

                @include breakpoint(nav) {
                    @include font(18px, 42px);
                }

                margin: -4px;
                padding: 4px;
                color: inherit;
                text-decoration: none;
                white-space: nowrap;
            }
