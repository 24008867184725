.header-action {
    padding: 7px 8px;
}

.header-action--text {
    @include font(14px, 24px);

    position: relative;
    text-decoration: none;
    color: $c-black;
    padding: 2px 7px 1px;
    transition: color .3s ease;

    @include breakpoint(desktop) {
        @include font(13px, 24px);
    }

    @include breakpoint(nav) {
        @include font(18px, 24px);
    }

    &:hover {
        text-decoration: none;
    }

    .header--no-transition & {
        transition: none;
    }

    .header--white & {
        color: $c-white;
    }

    .header--scroll & {
        color: $c-black;
    }
}

    .header-action__text {
        @include hideSafely;
    }

    .header-action__icon {
        color: inherit;

        svg {
            display: block;
            fill: $c-black;
            transition: fill .3s ease;

            @include breakpoint(tiny) {
                max-width: 18px;
            }

            .header--no-transition & {
                transition: none;
            }

            .header--white & {
                fill: $c-white;
            }

            .header--scroll & {
                fill: $c-black;
            }

            &.icon-basket {
                margin-top: -3px;
            }
        }
    }

    .header-action__icon--text {
        position: relative;
        line-height: 1;
        border-bottom: 1px solid transparent;

        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 0;
            height: 1px;
            background: rgba($c-black, 0.2);
            transition: width .3s ease;
        }

        .header-action:hover & {

            &:after {
                width: 100%;
            }
        }
    }

    .header-action__count {
        @include font(12px, 12px);

        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%)
    }
