/// Photoshop tracking to letter spacing
/// @param {String | Number} $photoshop-value
/// @author Brendan Patterson <brendan@d3r.com>

@mixin tracking($photoshop-value) {

    // Check if value is a number
    @if type-of($photoshop-value) == "number" {
        // If its 0 change to 'normal' which is UA default
        @if $photoshop-value == "0" {
            letter-spacing: normal;
        }
        // Otherwise divide by 1000 which is approx calculation
        @else {
            letter-spacing: #{calc($photoshop-value / 1000)}em;
        }
    }

    // Allow for keywords
    @else if $photoshop-value == "normal", "default", "none" {
        letter-spacing: normal;
    }

    // Warn if anything else and apply UA default
    @else {
        @warn "expected numerical value in tracking mixin";
        letter-spacing: normal;
    }
}
