@use "sass:math";

.popup {
    @include lightondark;

    position: relative;
    max-width: 450px;
    margin: 0 auto;
    padding: 40px 30px;
    color: $c-white;
    background: $c-black;

    @include breakpoint(tablet) {
        max-width: none;
    }
}

.popup--light {
    color: $c-black;
    background: $c-white;
}

.popup--large {
    max-width: 520px;
    padding: $site-gutter;

    @include breakpoint(phablet) {
        max-width: none;
        padding: $site-gutter--mobile;
    }
}

.popup--extra-large {
    max-width: 746px;
    padding: $site-gutter;

    @include breakpoint(phablet) {
        max-width: none;
        padding: $site-gutter--mobile;
    }
}

.popup--super {
    max-width: 960px;

    @include breakpoint(phablet) {
        max-width: none;
        padding: $site-gutter--mobile;
    }
}

.popup--medium {
    max-width: 416px;
    padding: $site-gutter;

    @include breakpoint(phablet) {
        max-width: none;
        padding: $site-gutter--mobile;
    }
}

.popup--takeover {
    position: fixed;
    background-color: $c-white;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: none;
    left: 0;
    z-index: 1000;
    overflow: scroll;
}

.popup--flush {
    padding: 0;
}

    .popup__title {
        @include font(20px, 24px);

        margin-bottom: 20px;

        .popup--large & {
            @include responsive-h2;
        }

        .popup--medium & {
            @include h3;
        }

        &--normal-case span {
            text-transform: none;
        }
    }

    .popup__title--center {
        text-align: center;
    }

    .popup__text {
        @include h5;
        @include margin(bottom, 20px);
    }

    .popup__text--center {
        text-align: center;
    }

    .popup__text--flush {
        margin-bottom: 0;
    }

    .popup__text--large {
        @include font(18px, 26px);

        @include breakpoint(tablet) {
            @include font(16px, 24px);
        }
    }

    .popup__text--small {
        @include body;
    }

    .popup__list {
        @include h5;

        margin-bottom: 20px;

        li {
            display: flex;
            margin-bottom: 10px;
        }

            svg {
                flex: 0 0 auto;
                width: 11px;
                height: 8px;
                margin-top: 5px;
                margin-right: 20px;
            }
    }

    .popup__form {
        display: flex;
        flex-direction: column;

        @include breakpoint(tablet) {
            max-width: 300px;
            margin: 0 auto;
        }

        label {
            @include h5;

            margin-bottom: 10px;
            color: $c-white;
            text-transform: uppercase;

            .popup--light & {
                color: $c-black;
                text-transform: none;
            }
        }

        .select-replace {
            background: $c-white;
        }

            select {
                height: 40px;
            }
    }

    .popup__form--center {
        align-items: center;
    }

    .popup__link {
        margin: -4px;
        padding: 4px;
        color: inherit;
        text-decoration: none;

        &:focus:not(:hover) {
            @include focus($color: $c-white);

            .popup--light & {
                @include focus;
            }
        }
    }

    .popup__link--bold {
        @include boldText;
    }

    .popup__buttons {
        @include margin(bottom, 20px);

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: -5px;
        margin-right: -5px;
        margin-left: -5px;
        list-style: none;

        > * {
            margin: 5px;
        }
    }

    .popup__buttons--left {
        justify-content: flex-start;
    }

.popup--order-swatches {
    .popup__form {
        select {
            height: 63px;
        }
    }
}

.popup--membership-benefits {
    .richtext {
        p {
            @include h5;
        }

        ul {
            @include h5;

            list-style: none;
            margin: 0 0 20px 0;
            padding: 0;

            li {
                display: flex;
                margin-bottom: 10px;

                &:before {
                    display: none;
                }
            }

            svg {
                flex: 0 0 auto;
                width: 11px;
                height: 8px;
                margin-top: 5px;
                margin-right: 20px;
            }
        }
    }
}

.popup--modular-selector {
    padding: 20px 25px 80px;

    @include breakpoint(phablet) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .popup__logo {
        display: block;

        svg {
            max-width: 130px;
        }
    }

    .popup__content {
        max-width: 1120px;
        margin: 50px auto 0;
    }

        .popup__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            border-bottom: 1px solid $c-border;
            margin-bottom: 20px;

            @include breakpoint(phablet) {
                flex-direction: column;
                align-items: start;
            }
        }

            .popup__title {
                @include responsive-h2;

                color: $c-black;
                margin: 0;

                @include breakpoint(phablet) {
                    @include margin(bottom, 20px);
                }
            }

        .popup__items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 25px;

            @include breakpoint(phablet) {
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }
        }

            .popup__item {
                @include margin(bottom, 7px);

                text-decoration: none;
                color: $c-black;

                &:hover {
                    .popup__item-title {
                        text-decoration: underline;
                    }
                }
            }

                .popup__image {
                    @include aspect(math.div(3,4));

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                .popup__item-title {
                    @include margin(top, 12px);
                    @include margin(bottom, 15px);
                    @include font(18px, 24px);

                    @include breakpoint(tablet) {
                        @include font(16px, 20px);
                    }
                }

                .popup__item-price {
                    @include body;
                }
}
