@import 'tools/mixins/margin';

.page-intro {}

    .page-intro--center {
        text-align: center;
    }

    .page-intro--narrow {
        max-width: 460px;
    }

    .page-intro__title,
    .page-intro__sub-title,
    .page-intro__desc {
        max-width: 795px;

        .page-intro--center & {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .page-intro__title {
        @include page-intro-title;
        @include margin(bottom, 17px);

        &--wide {
            max-width: none;
        }
    }

    .page-intro__sub-title {
        @include large-subtitle;
        @include margin(bottom, 17px);

        &--wide {
            max-width: none;
        }
    }

    .page-intro__desc {
        @include page-intro-subtitle;
        @include margin(top, 20px, false);
        @include margin(bottom, 20px, false);

        a {
            margin: -4px;
            padding: 4px;
            color: $c-brand;
        }
    }

    .page-intro__button {
        margin-top: 35px;
    }

    .page-intro__buttons {
        > * {
            @include margin(right, 10px);
        }
    }
