.wishlist-popup {
}

    .wishlist-popup__content {
        padding: 40px 110px;

        @include breakpoint(tablet) {
            padding: 40px $site-gutter--mobile;
        }
    }

            .wishlist-popup__title {
                @include font(32px, 38px);

                text-align: center;
                margin-bottom: 20px;

                @include breakpoint(tablet) {
                    @include font(24px, 30px);

                    margin-bottom: 12px;
                }
            }

            .wishlist-popup__copy {
                @include font(18px, 26px);

                text-align: center;
                margin-bottom: 20px;

                @include breakpoint(tablet) {
                    @include font(16px, 24px);
                }
            }

            .wishlist-popup__action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 25px;
                margin-bottom: 50px;

                @include breakpoint(tablet) {
                    gap: 15px;
                }
            }

                .wishlist-popup__url {
                    padding: 9px 15px;
                    border: 1px solid $c-border;
                    border-radius: 4px;
                    overflow: hidden;
                    width: 100%;

                    @include breakpoint(tablet) {
                        padding: 6px 15px;
                    }

                    span {
                        @include font(12px, 24px);
                    }

                    p {
                        @include font(18px, 24px);

                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @include breakpoint(tablet) {
                            margin-top: -4px;
                        }
                    }
                }

    .wishlist-popup__form {
        padding: 45px 110px 50px;
        background-color: $c-grey;
    }

        .wishlist-popup__fieldset {
            border: 1px solid $c-border;

            .field--checkbox {
                label {
                    @include font(14px, 20px);

                    padding: 18px 16px 22px;
                }
            }
        }

        .wishlist-popup__button {
            margin-top: 25px;
            text-align: center;
        }
