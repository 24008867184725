.overlay__close {
    background: transparent;
    width: $site-gutter;
    height: $site-gutter;
    line-height: $site-gutter;
    border-radius: 100%;
    opacity: 1;

    @include breakpoint(tablet) {
        top: $site-gutter--mobile;
        right: $site-gutter--mobile;
    }

    &:focus:not(:hover) {
        @include focus(100%, ($c-white, $c-black), 2px);
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: $c-black;
        stroke: $c-black;
        pointer-events: none;
    }
}

.overlay__close.overlay__close {
    top: 20px;
    right: 20px;

    svg {
        width: 10px;
        height: 10px;
        stroke: $c-black;
    }
}

.overlay--takeover .overlay__container .overlay__close {
    margin-top: 10px;
}

.overlay--sidebar.overlay--sidebar-right {
    z-index: 999999;

    .overlay__close {
        left: auto;
        right: 16px;
        top: 19px;
    }
}
