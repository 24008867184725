.icon-account {
    width: 18px;
    height: 20px;
}

.icon-arrow-down {
    width: 9px;
    height: 6px;
}

.icon-arrow-left-medium {
    width: 12px;
    height: 20px;
}

.icon-arrow-left {
    width: 10px;
    height: 15px;
}

.icon-arrow-right-medium {
    width: 12px;
    height: 20px;
}

.icon-arrow-right {
    width: 10px;
    height: 15px;
}

.icon-arrow-small-down {
    width: 9px;
    height: 6px;
}

.icon-arrow-small-left {
    width: 5px;
    height: 8px;
}

.icon-arrow-small-right {
    width: 5px;
    height: 8px;
}

.icon-arrow-small-up {
    width: 9px;
    height: 6px;
}

.icon-arrow-up {
    width: 12px;
    height: 7px;
}

.icon-basket {
    width: 18px;
    height: 20px;
}

.icon-close {
    width: 15px;
    height: 15px;
}

.icon-cross {
    width: 50px;
    height: 50px;
}

.icon-download {
    width: 8px;
    height: 10px;
}

.icon-external {
    width: 10px;
    height: 10px;
}

.icon-facebook {
    width: 10px;
    height: 18px;
}

.icon-filter {
    width: 13px;
    height: 11px;
}

.icon-info {
    width: 13px;
    height: 13px;
}

.icon-instagram {
    width: 18px;
    height: 18px;
}

.icon-minus {
    width: 13px;
    height: 1px;
}

.icon-padlock {
    width: 22px;
    height: 27px;
}

.icon-pause {
    width: 64px;
    height: 64px;
}

.icon-play {
    width: 64px;
    height: 64px;
}

.icon-plus {
    width: 13px;
    height: 13px;
}

.icon-search {
    width: 18px;
    height: 18px;
}

.icon-select {
    width: 10px;
    height: 6px;
}

.icon-share {
    width: 11px;
    height: 12px;
}

.icon-soho-home {
    width: 184px;
    height: 20px;
}

.icon-star-empty {
    width: 14px;
    height: 14px;
}

.icon-star-full {
    width: 14px;
    height: 14px;
}

.icon-star-half {
    width: 14px;
    height: 14px;
}

.icon-tick {
    width: 11px;
    height: 8px;
}

.icon-toggle-nav {
    width: 17px;
    height: 11px;
}

.icon-twitter {
    width: 20px;
    height: 16px;
}

.icon-wishlist-full {
    width: 21px;
    height: 18px;
}

.icon-wishlist {
    width: 21px;
    height: 18px;
}

