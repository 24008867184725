@import '@accessible360/accessible-slick/slick/slick.scss';

.carousel--notice {
    .slick-track {
        display: flex;
        font-size: 0;
    }

        .slick-slide {
            height: auto;
            outline: none;

            > div {
                height: 100%;
            }
        }
}

.carousel--datepicker {
    .slick-arrow {
        position: absolute;
        padding: 10px 17px;
        top: -50px;
        background: none;

        svg {
            width: 11px;
            height: 8px;
        }
    }

    .slick-disabled {
        fill: $c-button--disabled;
    }

    .slick-prev {
        right: 20px;

        @include breakpoint(phablet) {
            right: 35px;
        }
    }

    .slick-next {
        right: -17px;

        @include breakpoint(phablet) {
            right: 0;
        }
    }

    .slick-list {
        padding-bottom: 1px;
    }

    .slick-slide {
        border: none;
    }
}

.carousel--tabbed-products {
    .slick-list {
        padding-right: 20%;
        margin: 0 0 0 -5px;

        @include breakpoint(phablet) {
            padding-right: 10%;
            margin: 0 0 0 -8px;
        }
    }

    .slick-track {
        min-width: calc(100vw);
        margin-left: 0;
    }

    .slick-slide {
        padding: 0 5px;

        @include breakpoint(phablet) {
            padding: 0 8px;
        }
    }

    .slick-arrow {
        position: absolute;
        bottom: -10px;

        @include breakpoint(phablet) {
            display: none;
        }
    }

    .slick-prev {
        right: calc($site-gutter + 30px);
    }

    .slick-next {
        right: $site-gutter;
    }
}

.carousel--no-tabs {
    .slick-arrow {
        top: 50%;
        transform: translateY(-50%);
    }
}

.carousel--gallery {
    position: relative;

    .slick-track {
        display: flex;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        background: none;
        width: 40px;
        height: 40px;
        display: grid;
        place-items: center;
        padding: 0;

        &.slick-disabled {
            opacity: 0.5;
        }
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}

.carousel--mosaic {
    .slick-list {
        @include breakpoint(tablet) {
            padding-right: 30%;
            margin-left: -8px;
        }
    }

    .slick-track {
        @include breakpoint(tablet) {
            padding-left: 16px;
        }
    }

    .slick-slide {
        @include breakpoint(tablet) {
            margin: 0 8px;
        }
    }
}
