@use "sass:math";

.wishlist {
    @include breakpoint(tablet) {
        max-width: $max-content-width;
        margin: 0 auto;
    }
}

    .wishlist__copy {
        margin-top: 10px
    }

    .wishlist__placeholder {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 25px;
        margin: 50px 0;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            margin-top: 25px
        }
    }

        .wishlist__image {
            @include aspect(math.div(4, 3));

            @include breakpoint(tablet) {
                order: 1;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .wishlist__title--med {
            @include responsive-h2;

            margin-bottom: 35px;
        }

        .wishlist__title--small {
            @include font(18px, 24px);

            margin-bottom: 15px;
        }

        .wishlist__copy--mb {
            margin-bottom: 20px;
        }

        .wishlist__cta--mb {
            margin-bottom: 45px;
        }

    .wishlist__item-grid {
        margin: 50px 0;

        @include breakpoint(tablet) {
            margin-top: 20px;
        }
    }

        .wishlist__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

            .wishlist__showing {
                margin-right: 10px;

                @include breakpoint(tablet) {
                    margin: 0;
                }
            }

            .wishlist__actions {
                display: flex;
                gap: 25px;

                @include breakpoint(tablet) {
                    gap: 10px;
                }
            }

        .wishlist__grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 25px;
            row-gap: 40px;
            margin: 30px 0 50px;

            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
                row-gap: 30px;
                margin-top: 20px;
            }
        }

    .wishlist__button {
        display: flex;
        justify-content: center;
    }

    span.wishlist__hide-mob {
        margin-left: -20px;
        padding-left: 0;
        padding-right: 23px;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    span.wishlist__hide-mob-icon {
        padding: 0;

        @include breakpoint(tablet) {
            display: none;
        }
    }

