@import "core/resources/sass/reset";
@import 'tools/mixins/_focus';

// Match most modern UA defaults
dd {
	display: block;
}

button,
a {

    &:focus {
        outline: none;
    }

    &:focus:not(:hover) {
        @include focus;
    }
}

.input {

    &:focus {
        @include focus;
    }
}

a {

    &:hover {
        text-decoration: underline;
    }
}
