.footer-links {
    position: relative;
    display: flex;

    @include breakpoint(tablet) {
        flex-wrap: wrap;
    }
}

.footer-links--center {
    justify-content: center;
}

    .footer-links__item {
        @include margin(right, $site-gutter);

        white-space: nowrap;

        @include breakpoint(tablet) {
            @include font(14px, 30px);
            margin: 0;
            padding-right: 10px;
            padding-left: 10px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        a {
            position: relative;
            margin: -4px;
            padding: 4px;
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }
