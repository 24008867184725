@import 'tools/mixins/_allow-for-bars';
@import 'tools/mixins/focus';

.quick-wishlist {
    @include allowForBars((
        default: $headerHeight + $noticeHeight,
        tablet: $headerHeightMobile + $noticeHeight,
        phablet: $headerHeightMobile + $noticeHeight
    ), top, true);

    position: fixed;
    right: 0;
    left: 0;
    z-index: 502;
}

.quick-wishlist--activating {
    bottom: 0;
    transition: background .3s .2s ease;
}

.quick-wishlist--active {
    background: rgba($c-black, 0.8);
    transition-delay: 0;
}

    .quick-wishlist__wrap {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }

        .quick-wishlist__container {
            display: none;

            .quick-wishlist--activating & {
                display: block;
                position: relative;
                width: 344px;
                padding: 20px 16px 24px;
                background: $c-white;
                border-radius: 0 0 4px 4px;
                opacity: 0;
                transition: opacity .3s ease;

                @include breakpoint(tablet) {
                    width: 100%;
                    border-right: none;
                    border-bottom: none;
                    border-left: none;
                    border-radius: 0;
                }
            }

            .quick-wishlist--active & {
                opacity: 1;
                transition-delay: .2s;
            }
        }

            .quick-wishlist__title {
                @include h4;

                margin-bottom: 24px;
            }

            .quick-wishlist__item {
                display: flex;
                margin-bottom: 24px;
            }

                .quick-wishlist__image {
                    margin-right: 20px;
                    width: 80px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .quick-wishlist__content {

                    > * {
                        @include body;

                        margin-bottom: 5px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

            .quick-wishlist__close {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 20px;
                right: 16px;
                width: 24px;
                height: 24px;
                background: $c-black;
                border-radius: 100%;

                &:focus:not(:hover) {
                    @include focus(100%, ($c-white, $c-black), 2px);
                }

                svg {
                    display: block;
                    fill: $c-white;
                    width: 7px;
                    height: 7px;
                }
            }
