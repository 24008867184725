/// Set the value of a numeric property at each breakpoint, and consider the prescence of admin/debug bars
///
/// @param {Array} $value Array of numeric values, one per breakpoint
/// @param {String} $property CSS property
/// @author Joe Adcock <joe@d3r.com>

@mixin allowForBars($value: 0, $property: top, $notice: false) {
    $debugHeight: 20px;
    $adminHeight: 44px;

    $value--default: 0;
    $value--nav: 0;
    $value--tablet: 0;
    $value--phablet: 0;

    $notice--default: false;
    $notice--nav: false;
    $notice--tablet: false;
    $notice--phablet: false;

    @if type-of($value) == map {
        @if type-of(map-get($value, default)) == number {
            $value--default: map-get($value, default);
        } @else if map-get($value, default) == null {
            $value--default: false;
        }
        @if type-of(map-get($value, nav)) == number {
            $value--nav: map-get($value, nav);
        } @else if map-get($value, nav) == null {
            $value--nav: false;
        } @else {
            $value--nav: $value--default;
        }
        @if type-of(map-get($value, tablet)) == number {
            $value--tablet: map-get($value, tablet);
        } @else if map-get($value, tablet) == null {
            $value--tablet: false;
        } @else {
            $value--tablet: $value--nav;
        }
        @if type-of(map-get($value, phablet)) == number {
            $value--phablet: map-get($value, phablet);
        } @else if map-get($value, phablet) == null {
            $value--phablet: false;
        } @else {
            $value--phablet: $value--tablet;
        }
    } @else if type-of($value) == number {
        $value--default: $value;
        $value--nav: $value;
        $value--tablet: $value;
        $value--phablet: $value;
    }

    @if type-of($notice) == map {
        @if type-of(map-get($notice, default)) == bool {
            $notice--default: map-get($notice, default);
        } @else if map-get($notice, default) == null {
            $notice--default: false;
        }
        @if type-of(map-get($notice, nav)) == bool {
            $notice--nav: map-get($notice, nav);
        } @else if map-get($notice, nav) == null {
            $notice--nav: $notice--default;
        }
        @if type-of(map-get($notice, tablet)) == bool {
            $notice--tablet: map-get($notice, tablet);
        } @else if map-get($notice, tablet) == null {
            $notice--tablet: $notice--nav;
        }
        @if type-of(map-get($notice, phablet)) == bool {
            $notice--phablet: map-get($notice, phablet);
        } @else if map-get($notice, phablet) == null {
            $notice--phablet: $notice--tablet;
        }
    } @else if type-of($notice) == bool {
        $notice--default: $notice;
        $notice--nav: $notice;
        $notice--tablet: $notice;
        $notice--phablet: $notice;
    }

    // notice--default: $notice--default;
    // notice--nav: $notice--nav;
    // notice--tablet: $notice--tablet;
    // notice--phablet: $notice--phablet;

    @if $value--default {
        #{$property}: $value--default;

        @if $notice--default {
            @supports (display: var(--prop)) {
                #{$property}: calc(#{$value--default} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
            }
        }
    }

    @if $value--nav {
        @include breakpoint(nav) {
            #{$property}: $value--nav;

            @if $notice--nav {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--nav} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }
    }

    @if $value--tablet {
        @include breakpoint(tablet) {
            #{$property}: $value--tablet;

            @if $notice--tablet {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--tablet} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }
    }

    @if $value--phablet {
        @include breakpoint(phablet) {
            #{$property}: $value--phablet;

            @if $notice--phablet {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--phablet} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }
    }

    .body--debug & {
        @if $value--default {
            #{$property}: $value--default + $debugHeight;

            @if $notice--default {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--default + $debugHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }

        @if $value--nav {
            @include breakpoint(nav) {
                #{$property}: $value--nav + $debugHeight;

                @if $notice--nav {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--nav + $debugHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet;

                @if $notice--tablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--tablet} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }
    }

    .body--admin & {
        @if $value--default {
            #{$property}: $value--default + $adminHeight;

            @if $notice--default {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--default + $adminHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }

        @if $value--nav {
            @include breakpoint(nav) {
                #{$property}: $value--nav + $adminHeight;

                @if $notice--nav {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--nav + $adminHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $adminHeight;

                @if $notice--tablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--tablet + $adminHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;

                @if $notice--phablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--phablet} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }
    }

    .body--debug.body--admin & {
        @if $value--default {
            #{$property}: $value--default + $debugHeight + $adminHeight;

            @if $notice--default {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--default + $debugHeight + $adminHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }

        @if $value--nav {
            @include breakpoint(nav) {
                #{$property}: $value--nav + $debugHeight + $adminHeight;

                @if $notice--nav {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--nav + $debugHeight + $adminHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $adminHeight;

                @if $notice--tablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--tablet + $adminHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;

                @if $notice--phablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--phablet} + (var(--noticeLineHeight) * var(--noticeLineCount)) + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }
    }

    &.notice-locale--exists {
        @if $value--default {
            #{$property}: $value--default;

            @if $notice--default {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--default} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }

        @if $value--nav {
            @include breakpoint(nav) {
                #{$property}: $value--nav;

                @if $notice--nav {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--nav} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet;

                @if $notice--tablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--tablet} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;

                @if $notice--phablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--phablet} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }
    }

    .body--debug &.notice-locale--exists {
        @if $value--default {
            #{$property}: $value--default + $debugHeight;

            @if $notice--default {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--default + $debugHeight} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }

        @if $value--nav {
            @include breakpoint(nav) {
                #{$property}: $value--nav + $debugHeight;

                @if $notice--nav {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--nav + $debugHeight} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet;

                @if $notice--tablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--tablet} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }
    }

    .body--admin &.notice-locale--exists {
        @if $value--default {
            #{$property}: $value--default + $adminHeight;

            @if $notice--default {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--default + $adminHeight} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }

        @if $value--nav {
            @include breakpoint(nav) {
                #{$property}: $value--nav + $adminHeight;

                @if $notice--nav {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--nav + $adminHeight} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $adminHeight;

                @if $notice--tablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--tablet + $adminHeight} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;

                @if $notice--phablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--phablet} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }
    }

    .body--debug.body--admin &.notice-locale--exists {
        @if $value--default {
            #{$property}: $value--default + $debugHeight + $adminHeight;

            @if $notice--default {
                @supports (display: var(--prop)) {
                    #{$property}: calc(#{$value--default + $debugHeight + $adminHeight} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                }
            }
        }

        @if $value--nav {
            @include breakpoint(nav) {
                #{$property}: $value--nav + $debugHeight + $adminHeight;

                @if $notice--nav {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--nav + $debugHeight + $adminHeight} + (var(--noticeLineHeight) * var(--noticeLineCount)));
                    }
                }
            }
        }

        @if $value--tablet {
            @include breakpoint(tablet) {
                #{$property}: $value--tablet + $adminHeight;

                @if $notice--tablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--tablet + $adminHeight} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;

                @if $notice--phablet {
                    @supports (display: var(--prop)) {
                        #{$property}: calc(#{$value--phablet} + (var(--noticeLocaleLineHeight) * var(--noticeLocaleLineCount)));
                    }
                }
            }
        }
    }
}

