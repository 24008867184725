.footer {
    @include breakpoint(tablet) {
        border-top: 1px solid $c-footer-border;
    }
}

.footer--enclosed {
    border-top: 1px solid $c-footer-border;
}

    .footer__top {
        padding-top: $band-tallest;
        padding-bottom: 12px;

        @include breakpoint(tablet) {
            padding-top: $band-tallest-mobile-adapt;
            padding-bottom: 20px;
        }
    }

    .footer__bottom {
        @include body;

        padding-top: 12px;
        padding-bottom: 60px;

        @include breakpoint(tablet) {
            padding-top: 20px;
            padding-bottom: 40px;
        }

        .footer--enclosed & {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

        .footer__wrap {
            @extend .wrap;
        }

        .footer__wrap--flex {
            display: flex;
            flex-wrap: wrap;

            @include breakpoint(tablet) {
                display: block;
            }
        }

        .footer__logo-wrapper {
            display: flex;
        }

            .footer__logo {
                margin: -8px;
                padding: 8px;

                @include breakpoint(tablet) {
                    margin-bottom: 10px;
                }

                svg {
                    display: block;
                    width: 111px;
                    height: 12px;
                    fill: $c-black;
                }
            }

        .footer__shipping-wrapper {

            &[move-id="desktop"] {
                margin-right: auto;
            }

            &[move-id="tablet"] {

                @include breakpoint(tablet) {
                    margin-bottom: 50px;
                }
            }
        }

            .footer__shipping {}

        .footer__links {}

        .footer__credit {

            #credit-link {
                white-space: nowrap;
            }

            a {
                margin: -4px;
                padding: 4px;
                color: inherit;
                text-decoration: none;
            }

            @include breakpoint(tablet) {
                margin-top: 8px;
            }
        }
