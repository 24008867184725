@import 'tools/mixins/_hide-safely';

.field {
    margin-bottom: 20px;

    &:last-child, &.field--last {
        margin-bottom: 0;

        &:not(.field-error) .input {
            border-bottom-width: 0px;
            border-bottom-style: none;
        }
    }

    &.parsley-success {
        position: relative;
    }

    label,
    dt {
        @include apply-map($f-label);
        @include font(12px, 16px);

        display: block;
        color: $f-label-color;
        margin-bottom: 10px;
        text-transform: none;

        span {
            color: $c-error;

            &.personalisation__price {
                color: inherit;
            }
        }
    }
    dt {
        @include h6;

        padding: 30px 15px 0;
        z-index: 1;
        position: absolute;
        top: 9px;
        right: auto;
        bottom: auto;
        left: 15px;
        padding: 0;
        transform: none;
        color: lighten($f-label-color, 50%);
    }

    .error {
        @include rem(14px);

        color: $c-error;
        padding: 5px 15px;
    }
}

.field--flush {
    margin-bottom: 0;
}

.field--date-of-birth {
    label {
        margin-bottom: 0;
        padding: 12px 16px 0;
    }
}

.field--center {
    display: flex;
    align-items: center;
}

    .field__optional--hidden .field__optional {
        display: none;
    }

    .field__optional {
        font-size: inherit;
        line-height: inherit;

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }

    .field-error:last-child .input {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    .field input.check_box {
        margin-right: 15px;
        display: inline-block;
        vertical-align: top;
    }

    .field.check_box label,
    label.check_box {
        @include apply-map($f-field);
        @include font(14px, 20px);

        text-transform: none;
        letter-spacing: normal;
        color: $c-body;

        .field--center & {
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }

        .check-replace, input.check_box {
            margin-right: -40px;
        }

        .check_box__text {
            display: inline-block;
            vertical-align: top;

            .field--center & {
                white-space: nowrap;
            }
        }
    }

    .field__icon {
        position: absolute;
        right: 18px;
        bottom: 17px;

        svg {
            display: block;
            fill: $c-black;
        }
    }

.input {
    @include apply-map($f-field);
    @include h4;

    margin: 0;
    color: $f-field-color;
    outline: none;
}

input.input,
textarea.input,
div.input,
.field dd {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid $c-border;

    transition: box-shadow .3s;
    -webkit-appearance: none;

    &:focus {
        @include focus;
    }
}
input.input,
div.input,
.field dd {
    height: 63px;
    padding: 4px 15px;
}
.field dd {
    color: lighten($f-field-color, 50%);
    padding-top: 25px;
}

textarea.input {
    display: block;
    margin-top: 1px;
    padding: 8px 15px;
    height: 200px;
    max-width: 100%;
}

.field--text-area-short {
    textarea {
        height: 126px;
    }
}

select {
    width: 100%;
    border: 1px solid $c-border;
    padding: 4px 15px;
    height: 63px;
    background-color: $c-white;
    border-radius: 0;
    -webkit-appearance: none;
    text-overflow: ellipsis;
    color: $c-body;

    &:focus {
        box-shadow: $field-outline;
    }
}

.select-replace {
    display: block;
    position: relative;
    margin: -4px -8px 0;
    padding: 4px 8px 0;
    background: transparent;
    overflow: hidden;

    select {
        @include rem(16px);

        position: relative;
        width: 100%;
        z-index: 2;
        -webkit-appearance: menulist-button;
        -moz-appearance: none;
        opacity: 0;
    }
}

    .select-replace--focused {
        @include focus;

        [data-background="dark"] & {
            @include focus($color: ($c-black, $c-white), $width: 2px);
        }
    }

    .select-replace__value {
        @include apply-map($f-field);
        @include font(15px, 36px);

        border-bottom: 1px solid $c-border;
        text-align: left;
        color: $c-black;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        vertical-align: middle;
        display: block;
        margin-bottom: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        padding: 4px 35px 4px 15px;

        .field:last-child:not(.field-error) & {
            border-bottom-width: 0px;
            border-bottom-style: none;
        }

        &:after {
            content: '';
            position: absolute;
            right: 17px;
            top: 50%;
            width: 11px;
            height: 11px;
            margin-top: -2px;
            border-right: 2px solid $c-black;
            border-bottom: 2px solid $c-black;
            transform: translateY(-50%) rotate(45deg);
            pointer-events: none;
        }
    }

.field-inline {
    font-size: 0;
}

.field.error,
.field-error {
    .input,
    .select-replace__value,
    select {
        border-color: $c-error;
    }
}

input[type="checkbox"] {
    appearance: none;
    border: 1px solid var(--c-input-border);
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: var(--c-input-background);
    transition: outline .2s ease;
    margin-right: 0.5em;
    vertical-align: text-bottom;
    outline: none;

    svg {
        opacity: 0;
        width: 10px;
        height: 10px;
        fill: $c-black;
    }

    &:disabled {
        background-color: var(--c-field-disabled);
    }

    &:checked {
        border-color: var(--c-field-focus);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
}

.field--large-checkbox {
    .check-replace {
        background-color: $c-white;
        width: 24px;
        height: 24px;

        svg {
            width: 15px;
            height: 15px;
        }
    }
}

.field--select.field--radio,
.field--permission {
    padding: 10px 16px 0;
    position: relative;
    overflow: hidden;
    fieldset {
        input {
            position: absolute;
            right: 110%;
        }
        .radio {
            display: inline-block;
            margin-right: 5px;
            span {
                color: inherit;
                font-weight: 400;
            }
        }

        .button--radio {
            background-color: $c-grey;

            &:hover {
                background-color: $c-black;
                span {
                    color: $c-white;
                }
            }
        }
    }

    p.error {
        padding-right: 0;
        padding-left: 0;
    }
}

.field--select.field--radio {
    input:checked + .button--radio {
        background-color: $c-button--hover;
    }
}

.field--address-lookup-toggle {
    @include apply-map($f-button);
    @include smallprint;

    border-bottom: 1px solid $c-border;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
}

.field__dates {
    display: flex;
    border-bottom: 1px solid $c-border;

    .field-error & {
        border-bottom: 1px solid $c-error;
    }

    .field {
        width: 80px;

        &:first-child {
            width: 70px;
        }

        &:last-child {
            width: 120px;
        }
    }

    input.input {
        border-bottom: none;
    }
}

.field--stripe,
dl.field {
    position: relative;
    margin-bottom: 0;

    &#payment_cv2_wrap {
        max-width: none;
    }
    .input {
        // padding: 25px 15px 4px;
        height: 41px;
    }
    .StripeElement--invalid {
        border-color: $c-error;
    }

    label {
        display: none;
        @include h6;

        padding: 30px 15px 0;
        z-index: 1;
        position: absolute;
        top: 9px;
        right: auto;
        bottom: auto;
        left: 15px;
        padding: 0;
        transform: none;
    }

    .error {
        padding: 12px 16px;
    }
}


.js {
    .antispam-hide {
        display: none;
    }
    .field--antispam {
        display: none;
    }
}
